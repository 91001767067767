import React from "react";

const fhirdirectoryRoutes = [
  {
    path: "/pharmacy",
    component: React.lazy(() => import("./pharamacy"))
  },
  {
    path: "/laboratory",
    component: React.lazy(() => import("./laboratory"))
  },
  // {
  //   path: "/directory",
  //   component: React.lazy(() => import("./providerdirectory"))
  // },
  {
    path: "/directory",
    component: React.lazy(() => import("./fhirdirectorytabs"))
  },
  {
    path: "/insurance",
    component: React.lazy(() => import("./insurance"))
  },
  {
    path: "/fhirlake",
    component: React.lazy(() => import("./FhirLake"))
  }
];

export default fhirdirectoryRoutes;