import React from "react";

const formsRoutes = [
  {
    path: "/auditlogs",
    component: React.lazy(() => import("./auditlogs"))
  },
  {
    path: "/security",
    component: React.lazy(() => import("./compliance"))
  },
  {
    path: "/performance",
    component: React.lazy(() => import("./performance"))
  }
];

export default formsRoutes;
