import React from "react";

const MLRoutes = [
  {
     path: "/ml-tool",
   
    component: React.lazy(() => import("./MLTree")),
  
  },
  {
    path: "/ai",
  
   component: React.lazy(() => import("./bi")),
 
 }
];

export default MLRoutes;
