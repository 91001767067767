import * as types from "../../actions/action-creators/types";
import initialState from "./init";

export default function (state = initialState, action) {
    state = Object.assign({}, state);
    switch (action.type) {
        case types.FETCH_AUDIT_LOGS_START:
            state.fetching = true;
            state.logs = [];
            break;
        case types.FETCH_AUDIT_LOGS_SUCCESS:
            state.fetching = false;
            break;
        case types.FETCH_AUDIT_LOGS_FAIL:
            state.fetching = false;
            state.fetchingError = action.payload.error;
            break;
        case types.FETCH_AUDIT_LOGS:
            state.logs = action.payload.logs;
            break;
        case "persist/REHYDRATE":
            state = action.payload ? action.payload.auditlogs : state;
            state.fetching = false;
            state.fetchingError = undefined;
            break;
    }
    return state;
}


