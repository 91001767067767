import React, { Component } from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


class Snackbar extends Component {
    render () {
        return <div className='error-message-wrapper'>
            <span className='custom-snack'>
                {this.props.message.toString()}
                <IconButton className='error-close-button' onClick={this.props.onClose}>
                    <CloseIcon style={{color:'black'}} />
                </IconButton>
            </span>
        </div>;
    }
}

export default Snackbar;