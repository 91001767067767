import React from "react";
//import { authRoles } from "../../auth/authRoles";

const PopRoutes = [
  {
     path: "/pop-health",
   
    component: React.lazy(() => import("./PopHealth")),
   // auth: authRoles.admin
  }
];

export default PopRoutes;
