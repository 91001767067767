import * as actionTypes from "./types";
import API from '../../../lib/api'
export function auditLogsFetchStarted() {
    return {
        type: actionTypes.FETCH_AUDIT_LOGS_START
    }
}
export function auditLogsFetchSuccess() {
    return {
        type: actionTypes.FETCH_AUDIT_LOGS_SUCCESS
    }
}

export function auditLogsFetchError(error) {
    return {
        type: actionTypes.FETCH_AUDIT_LOGS_FAIL,
        payload: { error }
    }
}
export function auditLogsFetch(logs) {
    return {
        type: actionTypes.FETCH_AUDIT_LOGS,
        payload: { logs }
    }
}


export function loadauditlogs() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(auditLogsFetchStarted());
            let url = `${window._env_.auditlogs}/${sessionStorage.sandboxId}/`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(auditLogsFetchSuccess());
                    dispatch(auditLogsFetch(res));

                })
                .catch(error => dispatch(auditLogsFetchError(error)));


        }
    }
}