import React from "react";

const PlatFormRoutes = [
  {
    path: "/clusterMonitoring",
    component: React.lazy(() => import("./ClusterMonitoring"))
  },
  {
    path: "/secretstore",
    component: React.lazy(() => import("./SecretStore"))
  },
  {
    path: "/apigateway",
    component: React.lazy(() => import("./Apigateway"))
  },
  {
    path: "/servicemesh",
    component: React.lazy(() => import("./serviecmesh"))
  }
];

export default PlatFormRoutes;
