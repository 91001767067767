export default {
  xsettings: {
    status: "",
    data:
     {
      "env": "local",
      "port": 80,
      "outPort": 11000,
      "smartLaunch": {
        "default": {
          "client_id": "03ebc390-4190-4ff8-9abe-6686c11a926e",
          "scope": "patient/. launch openid profile online_access"
        },
        "https://fhir-api-r3.collabkare.com/collabkare9/data": {
          "client_id": "03ebc390-4190-4ff8-9abe-6686c11a926e",
          "scope": "patient/. launch/patient openid profile online_access"
        }
      },
      "sandboxManager": {
        "defaultServiceUrl": "https://fhir-api-r3.collabkare.com/collabkare9/data",
        "baseServiceUrl_1": "https://reference-api1.collabkare.com",
    //    {
    //   "env": "local",
    //   "port": 80,
      
    //   "outPort": 11000,
    //   "smartLaunch": {
    //     "default": {
    //       "client_id": "03ebc390-4190-4ff8-9abe-6686c11a926e",
    //       "scope": "patient/. launch openid profile online_access"
    //     },
    //     "https://fhir-api-r3.collabkare.com/collabkare9/data": {
    //       "client_id": "03ebc390-4190-4ff8-9abe-6686c11a926e",
    //       "scope": "patient/. launch/patient openid profile online_access"
    //     }
    //   },
    //   "sandboxManager": {
    //     "defaultServiceUrl": window._env_.defaultServiceUrl,
    //     "baseServiceUrl_2": window._env_.baseServiceUrl_2,
    //     "baseServiceUrl_3": window._env_.baseServiceUrl_3,
    //     "baseServiceUrl_4": window._env_.baseServiceUrl_4,
    //     "baseServiceUrl_5": window._env_.baseServiceUrl_5,
    //     "baseServiceUrl_6": window._env_.baseServiceUrl_6,
    //     "baseServiceUrl_7": window._env_.baseServiceUrl_7,
    //     "baseServiceUrl_8": window._env_.baseServiceUrl_8,
    //     "baseServiceUrl_9": window._env_.baseServiceUrl_9,
    //     "oauthLogoutUrl": window._env_.oauthLogoutUrl,
    //     "managerLogoutUrl": window._env_.managerLogoutUrl,
    //     "oauthUserInfoUrl": window._env_.oauthUserInfoUrl,
    //     "metricsrequest": window._env_.metricsrequest,
    //     "metricserr": window._env_.metricserr,
    //     "metricslatency": window._env_.metricslatency,
    //     "auditlogs": window._env_.auditlogs,
    //     "sbmUrlHasContextPath": "false",
    //     "userManagementUrl": window._env_.userManagementUrl,
    //     "reservedEndpoints": [
    //       "this",
    //       "collabkare",
    //       "collabkare1",
    //       "collabkare2",
    //       "collabkare3",
    //       "collabkare4",
    //       "collabkare5",
    //       "collabkare6",
    //       "collabkare7",
    //       "collabkare9",
    //       "collabkare8",
    //       "collabkare9",
    //       "collabkare10",
    //       "manage",
    //       "management",
    //       "health",
    //       "system",
    //       "sandbox",
    //       "dashboard",
    //       "admin",
    //       "rest",
    //       "reset",
    //       "static",
    //       "open",
    //       "stu3",
    //       "data",
    //       "test",
    //       "error",
    //       "invalid",
    //       "1",
    //       "2",
    //       "3",
    //       "4",
    //       "5",
    //       "6"
    //     ],
    //     "hostOrg": "collabkare",
    //     "personaCookieTimeout": 180000,
    //     "personaCookieDomain": "collabkare.com",
    //     "sandboxManagerUrl": window._env_.sandboxManagerUrl,
    //     "sandboxManagerApiUrl": window._env_.sandboxManagerApiUrl,
    //     "fhirGuiUrl": window._env_.fhirGuiUrl
    //   }
    // }

  // },
  // {
    "env": "local",
    "port": 80,
    "outPort": 11000,
    "smartLaunch": {
    "default": {
    "client_id": "03ebc390-4190-4ff8-9abe-6686c11a926e",
    "scope": "patient/. launch openid profile online_access"
    },
    "https://test-r3.collabkare.com/collabkare9/data": {
    "client_id": "03ebc390-4190-4ff8-9abe-6686c11a926e",
    "scope": "patient/. launch/patient openid profile online_access"
    }
    },
    "sandboxManager": {
      "defaultServiceUrl": "https://fhir-api-r3.collabkare.com/collabkare9/data",
      "baseServiceUrl_1": "https://reference-api1.collabkare.com",
      "baseServiceUrl_2": "https://reference-api2.collabkare.com",
      "baseServiceUrl_3": "https://reference-api3.collabkare.com",
      "baseServiceUrl_4": "https://reference-api4.collabkare.com",
      "baseServiceUrl_5": "https://reference-api5.collabkare.com",
      "baseServiceUrl_6": "https://fhir-api-r3.collabkare.com",
      "baseServiceUrl_7": "https://fhir-api-r4.collabkare.com",
      "baseServiceUrl_8": "https://fhir-api-r3.collabkare.com/collabkare9/open/Practitioner",
      "baseServiceUrl_9": "https://fhir-api-r4.collabkare.com/collabkare10/open/Practitioner",
      "oauthLogoutUrl": "https://openam.collabkare.com/am/XUI/#logout/",
      "managerLogoutUrl": "https://openam.collabkare.com/am/oauth2/connect/endSession",
      "oauthUserInfoUrl": "https://manager-api.collabkare.com/userinfo",
      "metricsrequest": "https://ftig-api.collabkare.com/metrics/requests",
      "metricserr": "https://ftig-api.collabkare.com/metrics/errors",
      "metricslatency": "https://ftig-api.collabkare.com/metrics/latency",
      "auditlogs": "https://ftig-api.collabkare.com/audit-log",
      "sbmUrlHasContextPath": "false",
      "userManagementUrl": "https://myaccount.collabkare.com/account-details",
      "reservedEndpoints": [
        "this",
        "collabkare",
        "collabkare1",
        "collabkare2",
        "collabkare3",
        "collabkare4",
        "collabkare5",
        "collabkare6",
        "collabkare7",
        "collabkare9",
        "collabkare8",
        "collabkare9",
        "collabkare10",
        "manage",
        "management",
        "health",
        "system",
        "sandbox",
        "dashboard",
        "admin",
        "rest",
        "reset",
        "static",
        "open",
        "stu3",
        "data",
        "test",
        "error",
        "invalid",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6"
      ],
      "hostOrg": "collabkare",
      "personaCookieTimeout": 180000,
      "personaCookieDomain": "collabkare.com",
      "sandboxManagerUrl": "https://manager.collabkare.com",
      "sandboxManagerApiUrl": "https://manager-api.collabkare.com",
      "fhirGuiUrl": "https://app.collabkare.com"
    }
  }
   },
},
  language: "en",
  rehydrated: false
};

