import React from "react";

const ProfileRoutes = [
  {
    path: "/:sandboxId/profiles",
    component: React.lazy(() => import("./Ig"))
  },
  // {
  //   path: "/:sandboxId/igs",
  //   component: React.lazy(() => import("./Ig"))
  // }
];

export default ProfileRoutes;
