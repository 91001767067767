import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Stack, Button,Grid } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';


function generateDownload(canvas, crop, fileName) {
  if (!crop || !canvas) {
    return;
  }
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      // returning an error

      blob.name = fileName;
      // creating a Object URL representing the Blob object given
      window.URL.createObjectURL(blob);
      resolve({ blob: blob });
    }, "image/jpeg");
  });
}

function setCanvasImage(image, canvas, crop) {
  if (!crop || !canvas || !image) {
    return;
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext("2d");
  // refer https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
  const pixelRatio = window.devicePixelRatio;

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  // refer https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setTransform
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "high";

  // refer https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );
}

export default function ImageCrop(props) {
  const { handleImageUpload, setSelectedFile,profilePic} = props;
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [showCrop, setShowCrop] = useState(false);
  const [file, setFile] = useState();
  let [heightCust, setHeightCust]=  useState(100)

  let [crop, setCrop] = useState({
    unit: "px",
    width: 300,
    height: 110,
    x: 0,
    y: 0,
  });
  const [completedCrop, setCompletedCrop] = useState(null);

  async function handleUpload() {
    if (showCrop) {
      const croppedBlob = await generateDownload(
        previewCanvasRef.current,
        completedCrop,
        "imageName"
      );
      const compressedBlob = await compressImage(croppedBlob.blob, 300, 300);
      handleImageUpload(compressedBlob.blob);
    } else {
      handleImageUpload(file);
    }
  }
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
  }, [completedCrop]);

  useEffect(()=>{
    if(profilePic){
      heightCust=200
      setHeightCust(heightCust)
      let crop={
         unit: "px",
        width: 300,
        height: 180,
        x: 0,
        y: 0,
      }
      setCrop(crop)
    }

  },[profilePic])

  const onDrop = useCallback((acceptedFiles) => {
    // Handle dropped files here
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;

        image.onload = async () => {
          const height = image.height;
          const width = image.width;
          if (height > 40 && width > 300) {
            setUpImg(reader.result);
            setShowCrop(true);
          } else {
            setUpImg(reader.result);
            setShowCrop(false);
          }
        };
      };
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*,.png",
  });

  async function compressImage(blob, maxWidth, maxHeight) {
    return new Promise((resolve) => {
      const img = new Image();
      const imgURL = URL.createObjectURL(blob);
      img.src = imgURL;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let newWidth = img.width;
        let newHeight = img.height;

        if (img.width > maxWidth) {
          newWidth = maxWidth;
          newHeight = (img.height * maxWidth) / img.width;
          canvas.width = newWidth;
        }else{
          canvas.width = newWidth;
        }

        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = (img.width * maxHeight) / img.height;
          canvas.height = newHeight;
        }else{
          canvas.height = newHeight;
        }

        

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((compressedBlob) => {
          URL.revokeObjectURL(imgURL);
          resolve({ blob: compressedBlob });
        }, "image/jpeg");
      };
    });
  }

  // on selecting file we set load the image on to cropper
  const onSelectFile = async (e) => {
    let ss = "";
    if (e.target.files && e.target.files.length > 0) {
      var reader = new FileReader();
      setFile(e.target.files[0]);
      //Read the contents of Image File.
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = async () => {
          var height = image.height;
          var width = image.width;
          if (height > 40 && width > 300) {
            setUpImg(reader.result);
            setShowCrop(true);
          } else {
            setUpImg(reader.result);
            setShowCrop(false);
          }
        };
      };
    }
  };

  async function getCroppedImageBlob(canvas, crop) {
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/png");
    });
  }

  return (
    <div className="App">
      <div {...getRootProps()} style={{ cursor: "pointer", borderStyle:"dashed", borderRadius:"5px", color:"#d7cece", marginTop:"25px" }}>
      <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{
              color: "#000",
              margin:"45px"
            }}
          >
        <input {...getInputProps()} accept="image/*,.png" />
        <p>Drag & drop an image here, or click to select one</p>
        <Button
          variant="outlined" size="small"   
          startIcon={<FolderOpenIcon />}
        >
          Browse
        </Button>
        </Stack>
      </div>
      {/* ... (rest of your component code) */}
      {showCrop ? (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
          <div style={{ marginLeft: "0px",marginTop: "25px" }}>
            {/* Canvas to display cropped image */}
            <canvas
              ref={previewCanvasRef}
              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
              style={{
                width: Math.round(completedCrop?.width),
                height: Math.round(completedCrop?.height),
              }}
            />
          </div>
          </Grid>
          <Grid item xs={12} sm={6}>
          <div style={{ margin: "25px", maxWidth: "none", overflowY:"hidden",/* Remove the maximum width constraint */
                          maxHeight: "300px", /* Limit the maximum height of the image */
                          // height: "auto", /* Maintain the image's aspect ratio */
                          // width: "auto", /* Ensure the image fits within the container's width */
                          display: "block"}} /* Remove any extra whitespace around the image */>
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) =>{setCompletedCrop(c)}}
              // maxWidth={100}
              // maxHeight={20}
              locked
              // aspect={350 / 50}
            />
          </div>
          </Grid>
        </Grid>
      ) : (
        <div>
          {" "}
          <img src={upImg} ref={previewCanvasRef} />
        </div>
      )}
      {upImg && showCrop && (
        <p>
          <b>Note:</b>The uploaded image exceeds the predefined dimensions.
          Please use the crop feature to adjust and frame the logo accordingly.
        </p>
      )}

      {/* <button
        type="button"
        disabled={!completedCrop?.width || !completedCrop?.height}
        onClick={() =>
          generateDownload(previewCanvasRef.current, completedCrop)
        }
      >
        Download cropped image
      </button> */}
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Button
          variant="contained"
          endIcon={<CloudUploadIcon />}
          onClick={handleUpload}
        >
          Upload
        </Button>
      </Stack>
    </div>
  );
}

ImageCrop.propTypes = {
  handleImageUpload: PropTypes.func,
  setSelectedFile: PropTypes.func,
  profilePic:PropTypes.bool,
};
