import React from "react";

const InteliRoutes = [
  {
    path: "/pro-analytics",
    component: React.lazy(() => import("./Reports"))
  },
  {
    path: "/bil-analytics",
    component: React.lazy(() => import("./BillingAnalytics"))
  }
];

export default InteliRoutes;