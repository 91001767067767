import * as actionTypes from "./types";
import API from '../../../lib/api'
export function FetchusersStarted() {
    return {
        type: actionTypes.FETCH_USERS_START
    }
}
export function FetchusersSuccess() {
    return {
        type: actionTypes.FETCH_USERS_SUCCESS
    }
}

export function FetchuserError(error) {
    return {
        type: actionTypes.FETCH_USERS_FAIL,
        payload: { error }
    }
}
export function usersFetch(orguser) {
    return {
        type: actionTypes.FETCH_USERS,
        payload: { orguser }
    }
}


export function loaduserdetails() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            let users = JSON.parse(localStorage.getItem('sbmUserId'))

            dispatch(FetchusersStarted());
            let url = `${window._env_.sandboxManagerApiUrl}/user/all`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(FetchusersSuccess());
                    dispatch(usersFetch(res));
                    //console.log(res)

                })
                .catch(error => dispatch(FetchuserError(error)));


        }
    }
}

export function userCreationStart() {
    return {
        type: actionTypes.CREATE_USERS_START
    }
}
export function userCreationSuccess() {
    return {
        type: actionTypes.CREATE_USERS_SUCCESS
    }
}
export function userCreationFail(error) {
    return {
        type: actionTypes.CREATE_USERS_FAIL,
        payload: { error }
    }
}
export function createuser(user) {
    let data = {

        email: user.email,
        hasAcceptedLatestTermsOfUse: true,
        termsOfUseAcceptances: [],
        tierLevel: null,
        first_name: user.first_name,
        middle_name: user.middle_name,
        last_name: user.last_name,
        date_of_birth: user.date_of_birth,
        gender: user.gender,
        adress_line1: user.adress_line1,
        adress_line2: user.adress_line2,
        city: user.city,
        state: user.state,
        country: user.country,
        zipcode: user.zipcode,
        phone: user.phone,
        postal_code: user.postal_code,
        social_security_number: user.social_security_number

    }
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            console.log(data)
            let users = JSON.parse(localStorage.getItem('sbmUserId'))
            let domain = users.substring(users.lastIndexOf("@") + 1);
            //let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(userCreationStart());
            let url = `${window._env_.sandboxManagerApiUrl}/${domain.split('.')[0]}/save`
            API.post(url, data, dispatch)

                .then(() => setTimeout(() => {
                    dispatch(userCreationSuccess())
                    dispatch(loaduserdetails())
                }, 550))

                .catch(error => {
                    console.log(error)
                    // dispatch(userCreationFail(error));

                })
        }
    }
}

export function userRoleStart() {
    return {
        type: actionTypes.FETCH_USER_ROLES_START
    }
}
export function userRoleSuccess() {
    return {
        type: actionTypes.FETCH_USER_ROLES_SUCCESS
    }
}
export function userRoleFail() {
    return {
        type: actionTypes.FETCH_USER_ROLES_FAIL,

    }
}
export function userRole(Roles) {
    return {
        type: actionTypes.FETCH_USER_ROLES,
        payload: { Roles }
    }
}
export function getRoles() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            let users = JSON.parse(localStorage.getItem('sbmUserId'))

            dispatch(userRoleStart());
            let url = `${window._env_.sandboxManagerApiUrl}/user/roles`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(userRole(res));
                    dispatch(userRoleSuccess());

                    // console.log(res)

                })
                .catch(dispatch(userRoleFail()));


        }
    }
}

export function vhlistStart() {
    return {
        type: actionTypes.FETCH_ORG_LIST_START
    }
}

export function vhlistsuccess() {
    return {
        type: actionTypes.FETCH_ORG_LIST_SUCCESS
    }
}
export function vhlist(vhlist) {
    return {
        type: actionTypes.FETCH_ORG_LIST,
        payload: {
            vhlist
        }
    }
}
export function vhlisterr() {
    return {
        type: actionTypes.FETCH_ORG_LIST_FAIL
    }
}

export function getVHlist() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            let users = JSON.parse(localStorage.getItem('sbmUserId'))
            let domain = users.substring(users.lastIndexOf("@") + 1);
            dispatch(vhlistStart());
            let url = `${window._env_.sandboxManagerApiUrl}/${domain.split('.')[0]}/vh-list`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(vhlist(res));
                    dispatch(vhlistsuccess());
                    //console.log(res)
                })
                .catch(dispatch(vhlisterr()));
        }
    }
}

export function orgusereditstart() {
    return {
        type: actionTypes.FETCH_USERS_EDIT_START
    }
}
export function orgusereditsuccess() {
    return {
        type: actionTypes.FETCH_USERS_EDIT_SUCCESS
    }
}
export function orgusereditfail() {
    return {
        type: actionTypes.FETCH_USERS_EDIT_FAIL
    }
}

export function edituser(user) {
    let data = {

        email: user.email,
        hasAcceptedLatestTermsOfUse: true,
        termsOfUseAcceptances: [],
        tierLevel: null,
        first_name: user.first_name,
        middle_name: user.middle_name,
        last_name: user.last_name,
        date_of_birth: user.date_of_birth,
        gender: user.gender,
        adress_line1: user.adress_line1,
        adress_line2: user.adress_line2,
        city: user.city,
        state: user.state,
        country: user.country,
        zipcode: user.zipcode,
        phone: user.phone,
        postal_code: user.postal_code,
        social_security_number: user.social_security_number

    }
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            console.log(data)
            let users = JSON.parse(localStorage.getItem('sbmUserId'))
            let domain = users.substring(users.lastIndexOf("@") + 1);
            //let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(orgusereditstart());

            let url = `${window._env_.sandboxManagerApiUrl}/user?sbmUserId=${user.email}`
            API.put(url, data, dispatch)

                .then(() => setTimeout(() => {
                    dispatch(orgusereditsuccess())
                }, 550))

                .catch(error => {
                    //console.log(error)
                    dispatch(orgusereditfail());

                })
        }
    }
}