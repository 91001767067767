import * as actionTypes from "./types";
import API from '../../../lib/api'
export function metricsFetchStarted() {
    return {
        type: actionTypes.FETCH_METRICS_WEEK_START
    }
}
export function metricsFetchSuccess() {
    return {
        type: actionTypes.FETCH_METRICS_WEEK_SUCCESS
    }
}

export function metricsFetchError(error) {
    return {
        type: actionTypes.FETCH_METRICS_WEEK_FAIL,
        payload: { error }
    }
}
export function metricsFetch(metrics) {
    return {
        type: actionTypes.FETCH_METRICS_WEEK,
        payload: { metrics }
    }
}
export function metricsFetcherrorStarted() {
    return {
        type: actionTypes.FETCH_METRICS_WEEK_ERROR_START
    }
}
export function metricsFetcherrorSuccess() {
    return {
        type: actionTypes.FETCH_METRICS_WEEK_ERROR_SUCCESS
    }
}

export function metricsFetcherrorError(error) {
    return {
        type: actionTypes.FETCH_METRICS_WEEK_FAIL,
        payload: { error }
    }
}
export function metricsFetcherror(metricserr) {
    return {
        type: actionTypes.FETCH_METRICS_ERROR_WEEK,
        payload: { metricserr }
    }
}

export function metricsFetchlatStarted() {
    return {
        type: actionTypes.FETCH_METRICS_WEEK_LATENCY_START
    }
}
export function metricsFetchlatSuccess() {
    return {
        type: actionTypes.FETCH_METRICS_WEEK_LATENCY_SUCCESS
    }
}

export function metricsFetchlatError(error) {
    return {
        type: actionTypes.FETCH_METRICS_WEEK_LATENCY_FAIL,
        payload: { error }
    }
}
export function metricsFetchlat(metricslatency) {
    return {
        type: actionTypes.FETCH_METRICS_LATENCY_WEEK,
        payload: { metricslatency }
    }
}
export function metricsFetchmonthStarted() {
    return {
        type: actionTypes.FETCH_METRICS_MONTH_START
    }
}
export function metricsFetchmonthSuccess() {
    return {
        type: actionTypes.FETCH_METRICS_MONTH_SUCCESS
    }
}

export function metricsFetchmonthError(error) {
    return {
        type: actionTypes.FETCH_METRICS_MONTH_FAIL,
        payload: { error }
    }
}
export function metricsFetchmonth(metricsmonth) {
    return {
        type: actionTypes.FETCH_METRICS_MONTH,
        payload: { metricsmonth }
    }
}



export function metricsFetcherrormonthStarted() {
    return {
        type: actionTypes.FETCH_METRICS_MONTH_ERROR_START
    }
}
export function metricsFetcherrormonthSuccess() {
    return {
        type: actionTypes.FETCH_METRICS_MONTH_ERROR_SUCCESS
    }
}

export function metricsFetcherrormonthError(error) {
    return {
        type: actionTypes.FETCH_METRICS_MONTH_ERROR_FAIL,
        payload: { error }
    }
}
export function metricsFetchmontherror(loadmontherr) {
    return {
        type: actionTypes.FETCH_METRICS_ERROR_MONTH,
        payload: { loadmontherr }
    }
}




export function metricsFetchmonlatStarted() {
    return {
        type: actionTypes.FETCH_METRICS_MONTH_LATENCY_START
    }
}
export function metricsFetchmonlatSuccess() {
    return {
        type: actionTypes.FETCH_METRICS_MONTH_LATENCY_SUCCESS
    }
}

export function metricsFetchmonlatError(error) {
    return {
        type: actionTypes.FETCH_METRICS_MONTH_LATENCY_FAIL,
        payload: { error }
    }
}
export function metricsFetchmonlat(metricsmonthlat) {
    return {
        type: actionTypes.FETCH_METRICS_LATENCY_MONTH,
        payload: { metricsmonthlat }
    }
}


export function metricsyearStarted() {
    return {
        type: actionTypes.METRICS_YEAR_START
    }
}
export function metricsyearSuccess() {
    return {
        type: actionTypes.METRICS_YEAR_SUCCESS
    }
}

export function metricsyearError(error) {
    return {
        type: actionTypes.METRICS_YEAR_FAIL,
        payload: { error }
    }
}
export function metricsyear(yearmetrics) {
    return {
        type: actionTypes.METRICS_YEAR,
        payload: { yearmetrics }
    }
}

export function metricsyearerrStarted() {
    return {
        type: actionTypes.METRICS_YEAR_ERROR_START
    }
}
export function metricsyearerrSuccess() {
    return {
        type: actionTypes.METRICS_YEAR_ERROR_SUCCESS
    }
}

export function metricsyearerrError(error) {
    return {
        type: actionTypes.METRICS_YEAR_ERROR_FAIL,
        payload: { error }
    }
}
export function metricserryear(yearerr) {
    return {
        type: actionTypes.METRICS_ERROR_YEAR,
        payload: { yearerr }
    }
}


export function metricsyearlatStarted() {
    return {
        type: actionTypes.METRICS_YEAR_LATENCY_START
    }
}
export function metricsyearlatSuccess() {
    return {
        type: actionTypes.METRICS_YEAR_LATENCY_SUCCESS
    }
}

export function metricsyearlatError(error) {
    return {
        type: actionTypes.METRICS_YEAR_LATENCY_FAIL,
        payload: { error }
    }
}
export function metricslatyear(yearlat) {
    return {
        type: actionTypes.METRICS_LATENCY_YEAR,
        payload: { yearlat }
    }
}



export function metricsdefaultStarted() {
    return {
        type: actionTypes.METRICS_DEFAULT_START
    }
}
export function metricsdefaultSuccess() {
    return {
        type: actionTypes.METRICS_DEFAULT_SUCCESS
    }
}

export function metricsdefaultError(error) {
    return {
        type: actionTypes.METRICS_DEFAULT_FAIL,
        payload: { error }
    }
}
export function metricsdefault(matericsdefault) {
    return {
        type: actionTypes.METRICS_DEFAULT,
        payload: { matericsdefault }
    }
}
export function metricsdefaulterrStarted() {
    return {
        type: actionTypes.METRICS_DEFAULT_ERR_START
    }
}
export function metricsdefaulterrSuccess() {
    return {
        type: actionTypes.METRICS_DEFAULT_ERR_SUCCESS
    }
}

export function metricsdefaulterrError(error) {
    return {
        type: actionTypes.METRICS_DEFAULT_ERR_FAIL,
        payload: { error }
    }
}
export function metricsdefaulterr(defaulterr) {
    return {
        type: actionTypes.METRICS_DEFAULT_ERR,
        payload: { defaulterr }
    }
}




export function metricsdefaultlatStarted() {
    return {
        type: actionTypes.METRICS_DEFAULT_LAT_START
    }
}
export function metricsdefaultlatSuccess() {
    return {
        type: actionTypes.METRICS_DEFAULT_LAT_SUCCESS
    }
}

export function metricsdefaultlatError(error) {
    return {
        type: actionTypes.METRICS_DEFAULT_LAT_FAIL,
        payload: { error }
    }
}
export function metricsdefaultlat(deflat) {
    return {
        type: actionTypes.METRICS_DEFAULT_LAT,
        payload: { deflat }
    }
}
export function loadmetrics() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(metricsFetcherrorStarted());
            let url = `${window._env_.metricsrequest}/${sessionStorage.sandboxId}?span=7days`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(metricsFetcherror(res));
                    dispatch(metricsFetcherrorSuccess());

                })
                .catch(error => dispatch(metricsFetcherrorError(error)));


        }
    }
}
export function loadmetricserr() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(metricsFetchStarted());
            let url = `${window._env_.metricserr}/${sessionStorage.sandboxId}?span=7days`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(metricsFetch(res));
                    dispatch(metricsFetchSuccess());

                })
                .catch(error => dispatch(metricsFetchError(error)));


        }
    }
}
export function loadmetricslatency() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(metricsFetchlatStarted());
            let url = `${window._env_.metricslatency}/${sessionStorage.sandboxId}?span=7days`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(metricsFetchlat(res));
                    dispatch(metricsFetchlatSuccess());

                })
                .catch(error => dispatch(metricsFetchlatError(error)));


        }
    }
}
export function loadmetricsmonth() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(metricsFetchmonthStarted());
            let url = `${window._env_.metricsrequest}/${sessionStorage.sandboxId}?span=1month`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(metricsFetchmonth(res));
                    dispatch(metricsFetchmonthSuccess());

                })
                .catch(error => dispatch(metricsFetchmonthError(error)));


        }
    }
}

export function loadmetricserrmonth() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(metricsFetcherrormonthStarted());
            let url = `${window._env_.metricserr}/${sessionStorage.sandboxId}?span=1month`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(metricsFetchmontherror(res));
                    dispatch(metricsFetcherrormonthSuccess());

                })
                .catch(error => dispatch(metricsFetcherrormonthError(error)));


        }
    }
}


export function loadmetricsmonthlatency() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(metricsFetchmonlatStarted());
            let url = `${window._env_.metricslatency}/${sessionStorage.sandboxId}?span=1month`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(metricsFetchmonlat(res));
                    dispatch(metricsFetchmonlatSuccess());

                })
                .catch(error => dispatch(metricsyearlatError(error)));


        }
    }
}



export function loadyearmetrics() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(metricsyearStarted());
            let url = `${window._env_.metricsrequest}/${sessionStorage.sandboxId}?span=365days`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(metricsyear(res));
                    dispatch(metricsyearSuccess());

                })
                .catch(error => dispatch(metricsyearError(error)));


        }
    }
}
export function loadyearerrmetrics() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(metricsyearerrStarted());
            let url = `${window._env_.metricserr}/${sessionStorage.sandboxId}?span=365days`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(metricserryear(res));
                    dispatch(metricsyearerrSuccess());

                })
                .catch(error => dispatch(metricsyearerrError(error)));


        }
    }
}



export function loadmetricslatyear() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(metricsyearlatStarted());
            let url = `${window._env_.metricslatency}/${sessionStorage.sandboxId}?span=365days`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(metricslatyear(res));
                    dispatch(metricsyearlatSuccess());

                })
                .catch(error => dispatch(metricsFetchmonthError(error)));


        }
    }
}



export function loaddefaultmetrics() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(metricsdefaultStarted());
            let url = `${window._env_.metricslatency}/${sessionStorage.sandboxId}`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(metricsdefault(res));
                    dispatch(metricsdefaultSuccess());

                })
                .catch(error => dispatch(metricsdefaultError(error)));


        }
    }
}



export function loaddefaulterrmetrics() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(metricsdefaulterrStarted());
            let url = `${window._env_.metricserr}/${sessionStorage.sandboxId}`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(metricsdefaulterr(res));
                    dispatch(metricsdefaulterrSuccess());

                })
                .catch(error => dispatch(metricsdefaulterrError(error)));


        }
    }
}


export function loaddefaultlatmetrics() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(metricsdefaultlatStarted());
            let url = `${window._env_.metricslatency}/${sessionStorage.sandboxId}`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(metricsdefaultlat(res));
                    dispatch(metricsdefaultlatSuccess());

                })
                .catch(error => dispatch(metricsdefaultlatError(error)));


        }
    }
}