import "../fake-db";
import "../styles/_app.scss";
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Provider } from "react-redux";
import { Router, Route ,Switch} from "react-router-dom";
import MatxTheme from "./MatxLayout/MatxTheme/MatxTheme";
import AppContext from "./appContext";
import history from "history.js";
import RegistrationForm from "./views/Registration/Registration";
import ActivationComponent from './views/Registration/CreateOrginization';
import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import Auth from "./auth/Auth";
import MatxLayout from "./MatxLayout/MatxLayoutSFC";
import AuthGuard from "./auth/AuthGuard";
import "react-awesome-query-builder/lib/css/styles.css";
// const App = () => {
//   return (
//     <AppContext.Provider value={{ routes }}>
//       <Provider store={Store}>
//         <MatxTheme>
//           <Auth>
//             <Router history={history}>
//               <AuthGuard>
//                 <MatxLayout />
//               </AuthGuard>
//             </Router>
//           </Auth>
//         </MatxTheme>
//       </Provider>
//     </AppContext.Provider>
//   );
// };

// export default App;
const App = () => {
  return (
    <MatxTheme>
      <Auth>
        <Router history={history}>
          <Switch>
            {/* Public Route - Registration */}
            <Route path="/registration" component={RegistrationForm} />
            <Route path="/createOrginization" component={ActivationComponent} />
            {/* Authenticated Routes */}
            <AuthGuard>
              <MatxLayout />
            </AuthGuard>
          </Switch>
        </Router>
      </Auth>
    </MatxTheme>
  );
};

export default App;
