import * as types from "../../actions/action-creators/types";
import initialState from "./init";

export default function (state = initialState, action) {
    state = Object.assign({}, state);
    switch (action.type) {
        case types.FETCH_PROVIDER_DIR_START:
            state.fetching = true;
            state.practitioner = [];
            break;
        case types.FETCH_PROVIDER_DIR_SUCCESS:
            state.fetching = false;
            break;
        case types.FETCH_PROVIDER_DIR_FAIL:
            state.fetching = false;
            state.fetchingError = action.payload.error;
            break;
        case types.FETCH_PROVIDER_DIR:
            state.practitioner = action.payload.practitioner;
            break;
        case "persist/REHYDRATE":
            state = action.payload ? action.payload.providerdirectory : state;
            state.fetching = false;
            state.fetchingError = undefined;
            break;
    }
    return state;
}


