import React from "react";
import { authRoles } from "../../auth/authRoles";
const fhirServerRoutes = [
  {
    path: "/:sandboxId/fhirInfo",
    component: React.lazy(() => import("../settings/settings")),
    auth: authRoles.guest
  },
  {
    path: "/:sandboxId/fhirfeatures",
    component: React.lazy(() => import("./fhirfeatures")),
    auth: authRoles.guest
  },
  {
    path: "/:sandboxId/fhirapi",
    component: React.lazy(() => import("./fhirapi")),
    auth: authRoles.guest
  },
  {
    path: "/:sandboxId/documentserver",
    component: React.lazy(() => import("./documentserver")),
    auth: authRoles.sa
  },
  {
    path: "/:sandboxId/notifications",
    component: React.lazy(() => import("./notifications"))
  },
  {
    path: "/:sandboxId/terminology",
    component: React.lazy(() => import("./terminology/terminology"))
  },
  {
    path: "/terminology",
    component: React.lazy(() => import("./terminology/terminology"))
  },
  {
    path: "/:sandboxId/datamanager",
    component: React.lazy(() => import("./datamanager/datamanager"))
  }
];

export default fhirServerRoutes;
