import React from "react";
//import { authRoles } from "../../auth/authRoles";

const SqldataViewRoutes = [
    {
        path: "/fhirinteligence",
        component: React.lazy(() => import("./sqldataview"))
      },
];

export default SqldataViewRoutes;
