import React from "react";
//import { authRoles } from "../../auth/authRoles";

const toolsRoutes = [
  {
    path: "/calendar",
    component: React.lazy(() => import("./Calendar")),
    //auth: authRoles.admin
  },
  {
    path: "/chat",
    component: React.lazy(() => import("./ChatServer")),
    //auth: authRoles.admin
  },
  {
    path: "/email",
    component: React.lazy(() => import("./Email"))
  }
];

export default toolsRoutes;
