import React from "react";

const LaunchScenariousRoutes = [
  {
     path: "/:sandboxId/launch",
   
    component: React.lazy(() => import("./LaunchScenarios")),
  
  }
];

export default LaunchScenariousRoutes;
