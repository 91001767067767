export default {
    loading: false,
    error: undefined,
    metrics: [],
    metricserr:[],
    hasloading:false,
    metricslatency:[],
    fetchlat:false,
    load:false,
    metricsmonth:[],
    loadmontherr:false,
    metricsmontherr:[],
    loadmonthlat:false,
    metricsmonthlat:[],
    yearmetrics:[],
    loadyear:false,
    yearerr:[],
    yearloaderr:false,
    yearlat:[],
    yearlatload:false,
    matericsdefault:[],
    loaddefault:false,
    fetcherrdef:false,
    defaulterr:[],
    deflat:[],
    loaddef:false

}


