import * as types from "./types";
import API from '../../../lib/api';


export function setSubscription(data) {
    return {
        type: types.SET_SUBSCRIPTION,
        payload: { data }
    }
}

export function setSubscriptionLoading(loading) {
    return {
        type: types.SET_SUBSCRIPTION_LOADING,
        payload: { loading }
    }
}


export function activeSubscription() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            dispatch(setSubscriptionLoading(true));
            let url = window.fhirClient.server.serviceUrl.replace('data', 'open') + '/Subscription?status=active'
            API.get(url, dispatch)
                .then(response => {
                    let data = [];

                    for (let key in response.entry) {
                        //  response.her
                        response.entry[key].resource.fullUrl = response.entry[key].fullUrl;
                        data.push(response.entry[key].resource);
                    }
                    // console.log("sub in actions");
                    // console.log(data);

                    dispatch(setSubscription(data));

                }).catch(e => {
                    console.log("Loading failed");
                })
        }
    }
}

export function deActiveSubscription() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            dispatch(setSubscriptionLoading(true));
            let url = window.fhirClient.server.serviceUrl.replace('data', 'open') + '/Subscription?status=off'
            API.get(url, dispatch)
                .then(response => {
                    let data = [];

                    for (let key in response.entry) {
                        //  response.her
                        response.entry[key].resource.fullUrl = response.entry[key].fullUrl;
                        data.push(response.entry[key].resource);
                    }
                    // console.log("sub in actions");
                    // console.log(data);

                    dispatch(setSubscription(data));

                }).catch(e => {
                    console.log("Loading failed");
                })
        }
    }
}
export function subFetchStarted() {
    return {
        type: types.FETCH_SUB_START
    }
}
export function subFetchSuccess() {
    return {
        type: types.FETCH_SUB_SUCCESS
    }
}

export function subFetchError(error) {
    return {
        type: types.FETCH_SUB_FAIL,
        payload: { error }
    }
}
export function subFetch(sub) {
    return {
        type: types.FETCH_SUB,
        payload: { sub }
    }
}

export function loadsubs() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            //let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(subFetchStarted());
            let url = window.fhirClient.server.serviceUrl.replace('data', 'open') + '/Subscription'
            API.get(url, dispatch)
                .then(res => {
                    dispatch(subFetch(res.entry));
                    dispatch(subFetchSuccess());

                })
                .catch(error => dispatch(subFetchError(error)));


        }
    }
}
// export function notificationCreation() {
//     return {
//         type: types.CREATE_SUB_START
//     }
// }
// export function notificationSuccess() {
//     return {
//         type: types.CREATE_SUB_SUCCESS
//     }
// }

// export function notificationError(err) {
//     return {
//         type: types.CREATE_SUB_FAIL,
//         payload: { err }
//     }
// }
// export function notification(notification) {
//     return {
//         type: types.CREATE_SUB,
//         payload: { notification }
//     }
// }
// export function createNotification(noti) {
//     let data =
//     {
//         resourceType: "Subscription",
//         id: noti.id,
//         status: noti.enable,
//         criteria: noti.criteria,
//         contact:
//             [{ system: "phone", value: "ext 4123" }],
//         end: new Date(),
//         reason: noti.description,

//         channel:
//         {
//             type: noti.channel,
//             endpoint: noti.endpoint,
//             payload: noti.payload

//         }
//     }
//     console.log(JSON.stringify(data))
//     return (dispatch, getState) => {
//         if (window.fhirClient) {
//             let state = getState();
//             //let configuration = state.config.xsettings.data.sandboxManager;
//             dispatch(notificationCreation());
//             let url = "https://fhir-api-r3.collabkare.com/TestR3Hospital/open/Subscription"
//             API.post(url, JSON.stringify(data), dispatch, true)

//             .finally(() => setTimeout(() => dispatch(notificationSuccess()), 550))
//                 // .then(res => {

//                 //     dispatch(notification(res));
//                 //     console.log(res)
//                 //     dispatch(notificationSuccess());
//                 //     alert('Details Saved Successfully...');
//                 // })
//                 .catch(error => dispatch(notificationError(error)));


//         }
//     }
// }




