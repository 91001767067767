import React from "react";

const formsRoutes = [
  {
    path: "/setup",
    component: React.lazy(() => import("./configure"))
  },
  {
    path: "/device",
    component: React.lazy(() => import("./devicesetup"))
  },
  {
    path: "/backup",
    component: React.lazy(() => import("./backup"))
  }
];

export default formsRoutes;