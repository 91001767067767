import React from "react";

const fhirQualityRoutes = [
  {
    path: "/:sandboxId/fhirecqms",
    component: React.lazy(() => import("./fhirecqms"))
  },
  
];

export default fhirQualityRoutes;