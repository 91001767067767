import React from "react";
import {  Redirect } from "react-router-dom";
//import { BrowserRouter as  Redirect } from 'react-router-dom';
import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import utilitiesRoutes from "./views/utilities/UtilitiesRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";
import virtualhospitalRoutes from './views/Virtualhospital/VirtualhospitalRoutes';
import materialRoutes from "./views/material-kit/MaterialRoutes";
import dragAndDropRoute from "./views/Drag&Drop/DragAndDropRoute";
import HomeRoutes from "./views/Home/HomeRoutes";
//import StartRoutes from './views/authentication/authenticationRoutes';
import SQLdataSetsRoutes from './views/sqldataview/saldataviewRoutes'
import formsRoutes from "./views/forms/FormsRoutes";
import mapRoutes from "./views/map/MapRoutes";
import LaunchAppRoutes from "./views/LaunchApp/LaunchAppRoutes";
import AppsRoutes from "./views/Apps/AppRoutes";
import toolsRoutes from "./views/tools/ToolsRoutes";
import BillingRoutes from "./views/Billing/billingRoutes";
import HelpRoutes from "./views/help/helpRoutes";
import DashRoutes from "./views/dashboards/dashboardsRoutes";
import configureRoutes from "./views/configure/configureRoutes";
import PopRoutes from "./views/ValueBasedSystem/PopRoutes";
import MLRoutes from "./views/AI-Studio/MLRoutes";
import fhirdirectoryRoutes from "./views/fhirdirectory/fhirRoutes";
import healthinfoRoutes from "./views/Healthinfoexc/healthinfoRoutes";
import MktRoutes from "./views/Mkt/MktRoutes";
import InteliRoutes from "./views/BusinessInteligence/InteliRoutes";
import overviewRoutes from "./views/overview/overviewRoutes";
import directoryRoutes from "./views/Directory/directoryRoutes";
import decissionRoutes from "./views/decisionsupport/decissionRoutes";
import fhiraccessRoutes from "./views/fhiraccess/fhiraccessRoutes";
import fhirqualityRoutes from "./views/fhirquality/fhirqualityRoutes";
import fhirserverRoutes from "./views/Fhirserver/fhirserverRoutes";
import ProfileRoutes from "./views/Profiles/ProfileRoutes"
import FhirMonitoringRoutes from "./views/fhirMonitoring/fhirmonitoringRoutes";
import PlatFormRoutes from "./views/Platformpages/platformRoutes";
import LaunchScenarious from "./views/LaunchScenarios/LaunchScenaroiusRoutes";
import PersonaRoutes from "./views/persona/personaRoutes";
import settingsRoutes from "./views/settings/settingRoutes";
import dataLakeRoutes from './views/datalake/datalakeroutes'
let sandboxId = localStorage.getItem('sandboxId');


const redirectRoute = [

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/start" />
  },
  // {
  //   path: "/after-auth",
  //   exact: true,
  //   component: () => <Redirect to="/after-auth" />
  // },
  // {
  //   path: "/start",
  //   component: () => <Redirect to="/start" />
  // },
  // {
  //   path: "/:sandboxId",
  //   exact: true,
  //   component: (match) =><Redirect to={`/${match.params.sandboxId}/home`} />
  //   },
  // {
  //   path: "/:sandboxId/ehr",
  //   exact: true,
  //   component: () => <Redirect to={`/${sandboxId}/ehr`} />
  // },
];

// const errorRoute = [
//   {
//     component: () => <Redirect to={'/' + sandboxId + "/session/404"} />
//   }
// ];

const routes = [

  ...sessionRoutes,
  ...LaunchAppRoutes,
  ...virtualhospitalRoutes,
  ...settingsRoutes,
  ...SQLdataSetsRoutes,
  ...FhirMonitoringRoutes,
  ...PlatFormRoutes,
  ...LaunchScenarious,
  ...PersonaRoutes,
  ...dashboardRoutes,
  ...HomeRoutes,
  ...materialRoutes,
  ...utilitiesRoutes,
  ...dragAndDropRoute,
  ...formsRoutes,
  ...BillingRoutes,
  ...HelpRoutes,
  ...DashRoutes,
  ...configureRoutes,
  ...toolsRoutes,
  ...PopRoutes,
  ...MLRoutes,
  ...overviewRoutes,
  ...directoryRoutes,
  ...dataLakeRoutes,
  ...mapRoutes,
  ...fhirdirectoryRoutes,
  ...healthinfoRoutes,
  ...MktRoutes,
  ...InteliRoutes,
  ...decissionRoutes,
  ...fhiraccessRoutes,
  ...fhirqualityRoutes,
  ...fhirserverRoutes,
  ...ProfileRoutes,
  ...redirectRoute,
  ...AppsRoutes,
  //...errorRoute,


];

export default routes;
