export default {
    oauthUser: {
        sbmUserId: null,
        email: null,
        name: null,
        roles:null
    },
    user: null,
    pendingUsers: [],
    rejectedUsers: [],
}
