import React from "react";

const OverviewRoutes = [
  {
    path: "/:sandboxId/overview",
    
    component: React.lazy(() => import("./overview"))
  },
  {
    path: "/:sandboxId/hospital",
    
    component: React.lazy(() => import("./hospital"))
  },
  {
    path: "/:sandboxId/FaciltiesUsers",
    
    component: React.lazy(() => import("./faciltiesUsers"))
  },
  {
    path: "/:sandboxId/practice",
    
    component: React.lazy(() => import("./practicetab"))
  },

  {
    path: "/:sandboxId/practicedetails",
    
    component: React.lazy(() => import("./practiceDetails"))
  },
  {
    path: "/:sandboxId/teamdetails",
    
    component: React.lazy(() => import("./TeamDetails"))
  },
  {
    path: "/:sandboxId/organisationmap",
    
    component: React.lazy(() => import("./organisationmap"))
  },
  // {
  //   path: "/:sandboxId/teams",
    
  //   component: React.lazy(() => import("./Teams"))
  // }
];

export default OverviewRoutes;
