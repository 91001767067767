// config
export const CONFIG_RESET = "config/RESET";
export const CONFIG_SET_XSETTINGS = "config/SET_XSETTINGS";
// Line 355:30:  'fhir_setDefinitionTypes' is not defined               no-undef
// Line 367:22:  'fhir_setFetchingProfilesByDefinition' is not defined  no-undef
// Line 372:30:  'fhir_setProfilesByDefinition' is not defined          no-undef
// Line 375:30:  'fhir_setFetchingProfilesByDefinition' is not defined  no-undef
// Line 383:18:  'fhir_setFetchingProfilesByDefinition' is not defined  no-undef
// Line 384:18:  'fhir_setFetchingProfilesByDefinition' is not defined  no-undef
// // fhir
export const FHIR_RESET = "fhir/RESET";
export const FHIR_SET_CONTEXT = "fhir/SET_CONTEXT";
export const FHIR_SET_PARSED_PATIENT_DEMOGRAPHICS = "fhir/FHIR_SET_PARSED_PATIENT_DEMOGRAPHICS";
export const FHIR_SET_SAMPLE_DATA = "fhir/SET_SAMPLE_DATA";
export const FHIR_SET_SMART = "fhir/SET_SMART";
export const FHIR_SET_META = "fhir/SET_META";
export const FHIR_SET_CUSTOM_SEARCH_RESULTS = "fhir/FHIR_SET_CUSTOM_SEARCH_RESULTS";
export const FHIR_SET_CUSTOM_SEARCH_EXECUTING = "fhir/FHIR_SET_CUSTOM_SEARCH_EXECUTING";
export const FHIR_SET_LOADING_RELATIVE_PROFILES = "fhir/FHIR_SET_LOADING_RELATIVE_PROFILES";
export const FHIR_SET_CUSTOM_SEARCH_RESULTS_NEXT = "FHIR_SET_CUSTOM_SEARCH_RESULTS_NEXT";
export const FHIR_SET_EXPORT_SEARCH_RESULTS = "FHIR_SET_EXPORT_SEARCH_RESULTS"
export const FHIR_SET_CUSTOM_SEARCH_GETTING_NEXT_PAGE = "fhir/FHIR_SET_CUSTOM_SEARCH_GETTING_NEXT_PAGE";
export const FHIR_SET_VALIDATION_RESULTS = "fhir/FHIR_SET_VALIDATION_RESULTS";
export const FHIR_SET_VALIDATION_EXECUTING = "fhir/FHIR_SET_VALIDATION_EXECUTING";
export const FHIR_SET_METADATA_LOADING = "fhir/FHIR_SET_METADATA_LOADING";
export const FHIR_SET_METADATA = "fhir/FHIR_SET_METADATA";
export const FHIR_SET_RESOURCES_COUNT = "fhir/FHIR_SET_RESOURCES_COUNT";
export const FHIR_SET_RESOURCES_LOADING = "fhir/FHIR_SET_RESOURCES_LOADING";
export const FHIR_SET_RESOURCES = "fhir/FHIR_SET_RESOURCES";
export const FHIR_SET_PROFDILES_LOADING = "fhir/FHIR_SET_PROFDILES_LOADING";
export const FHIR_SET_PROFDILESDS_LOADING = "fhir/FHIR_SET_PROFDILESDS_LOADING";
export const FHIR_SET_PROFDILES = "fhir/FHIR_SET_PROFDILES";
export const FHIR_SET_PROFDILESDS = "fhir/FHIR_SET_PROFDILESDS";
export const FHIR_SET_FETCHING_RESOURCE = "fhir/FHIR_SET_FETCHING_RESOURCE";
export const FHIR_SET_RESOURCE = "fhir/FHIR_SET_RESOURCE";
export const FHIR_SET_PROFDILE_RESOURCES = "fhir/FHIR_SET_PROFDILE_RESOURCES";
export const FHIR_CLEAN_VALIDATION_RESULTS = "FHIR_CLEAN_VALIDATION_RESULTS";
export const FHIR_SET_PROFDILES_UPLOADING = "FHIR_SET_PROFDILES_UPLOADING";
export const FHIR_SET_FILE_FETCHING = "FHIR_SET_FILE_FETCHING";
export const FHIR_SET_PROFDILES_UPLOADING_STATUS = "FHIR_SET_PROFDILES_UPLOADING_STATUS";
export const FHIR_SET_RELATIVE_PROFILES = 'FHIR_SET_RELATIVE_PROFILES'



// ui
export const UI_SET_CLIENT_WIDTH = "ui/SET_CLIENT_WIDTH";
export const UI_SET_FOOTER_HEIGHT = "ui/SET_FOOTER_HEIGHT";
export const UI_SET_INITIALIZED = "ui/SET_INITIALIZED";
export const UI_SET_RETINA = "ui/SET_RETINA";
export const UI_SET_THEME = "ui/SET_THEME";

// sandbox
export const RESET_SANDBOX = "RESET_SANDBOX";
export const SET_SANDBOX_EXPORT_STATUS = "SET_SANDBOX_EXPORT_STATUS";
export const SET_NOTIFICATIONS_LOADING = "SET_NOTIFICATIONS_LOADING";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const DELETE_SANDBOX = "DELETE_SANDBOX";
export const UPDATE_SANDBOX = "UPDATE_SANDBOX";
export const SELECT_SANDBOX = "SELECT_SANDBOX";
export const REMOVE_SANDBOX_USER = "REMOVE_SANDBOX_USER";
export const INVITE_NEW_USER = "INVITE_NEW_USER";
export const SET_FHIR_SERVER_URL = "SET_FHIR_SERVER_URL";
export const FETCH_SANDBOXES_START = "FETCH_SANDBOXES";
export const FETCH_SANDBOXES_SUCCESS = "FETCH_SANDBOXES_SUCCESS";
export const FETCH_SANDBOXES_FAIL = "FETCH_SANDBOXES_FAIL";
export const FETCH_SANDBOX_INVITES_START = "FETCH_SANDBOX_INVITES_START";
export const FETCH_SANDBOX_INVITES_SUCCESS = "FETCH_SANDBOX_INVITES_SUCCESS";
export const FETCH_SANDBOX_INVITES_FAIL = "FETCH_SANDBOX_INVITES_FAIL";
export const CREATING_SANDBOX = "CREATING_SANDBOX";
export const CREATE_SANDBOX_FAIL = "CREATE_SANDBOX_FAIL";
export const CREATE_SANDBOX_SUCCESS = "CREATE_SANDBOX_SUCCESS";
export const LOOKUP_SANDBOX_BY_ID_START = "LOOKUP_SANDBOX_BY_ID_START";
export const FETCHING_LOGIN_INFO = "FETCHING_LOGIN_INFO";
export const FETCHING_USER_LOGIN_INFO = "FETCHING_USER_LOGIN_INFO";
export const LOOKUP_SANDBOX_BY_ID_SUCCESS = "LOOKUP_SANDBOX_BY_ID_SUCCESS";
export const LOOKUP_SANDBOX_BY_ID_FAIL = "LOOKUP_SANDBOX_BY_ID_FAIL";
export const SET_DEFAULT_SANDBOX_USER = "SET_DEFAULT_SANDBOX_USER";
export const SET_RESETTING_CURRENT_SANDBOX = "SET_RESETTING_CURRENT_SANDBOX";
export const SET_DELETING_CURRENT_SANDBOX = "SET_DELETING_CURRENT_SANDBOX";
export const SET_INVITES_LOADING = "SET_INVITES_LOADING";
export const SET_INVITES = "SET_INVITES";
export const SET_DATA_IMPORTING = "SET_DATA_IMPORTING";
export const FETCHING_SINGLE_LOCATION = "FETCHING_SINGLE_LOCATION";
export const SET_SINGLE_LOCATION = "SET_SINGLE_LOCATION";
export const SET_SINGLE_LOCATION_LOAD_ERROR = "SET_SINGLE_LOCATION_LOAD_ERROR";
export const FETCHING_SINGLE_INTENT = "FETCHING_SINGLE_INTENT";
export const SET_SINGLE_INTENT = "SET_SINGLE_INTENT";
export const SET_SINGLE_INTENT_LOAD_ERROR = "SET_SINGLE_INTENT_LOAD_ERROR";
export const FETCHING_SINGLE_RESOURCE = "FETCHING_SINGLE_RESOURCE";
export const SET_SINGLE_RESOURCE = "SET_SINGLE_RESOURCE";
export const SET_SINGLE_RESOURCE_LOAD_ERROR = "SET_SINGLE_RESOURCE_LOAD_ERROR";
export const SET_IMPORT_RESULTS = "SET_IMPORT_RESULTS";
export const CLEAR_RESULTS = "CLEAR_RESULTS";
export const UPDATING_USER = "UPDATING_USER";
export const SET_LOGIN_INFO = "SET_LOGIN_INFO";
export const SET_USER_LOGIN_INFO = "SET_USER_LOGIN_INFO";
export const SET_SANDBOX_SELECTING = "SET_SANDBOX_SELECTING";
export const FETCHING_SINGLE_ENCOUNTER = "FETCHING_SINGLE_ENCOUNTER";
export const SET_SINGLE_ENCOUNTER = "SET_SINGLE_ENCOUNTER";
export const SET_USER_INVITING = "SET_USER_INVITING";
export const SET_SINGLE_ENCOUNTER_LOAD_ERROR = "SET_SINGLE_ENCOUNTER_LOAD_ERROR";
export const ADDING_CUSTOM_CONTENT = "ADDING_CUSTOM_CONTENT";

// users
export const SAVE_ENDPOINT_INDEX = "SAVE_ENDPOINT_INDEX";
export const SAVE_OAUTH_USER = "SAVE_OAUTH_USER";
export const SAVE_SANDBOX_USER = "SAVE_SANDBOX_USER";

// persona
export const LOOKUP_PERSONAS_START = "LOOKUP_PERSONAS_START";
export const LOOKUP_PERSONAS_SUCCESS = "LOOKUP_PERSONAS_SUCCESS";
export const LOOKUP_PERSONAS_FAIL = "LOOKUP_PERSONAS_FAIL";
export const CREATE_PERSONA_START = "CREATE_PERSONA_START";
export const CREATE_PERSONA_END = "CREATE_PERSONA_END";
export const RESET_PERSONAS = "RESET_PERSONAS";

// patient
export const PATIENT_DETAILS_FETCH_STARTED = "PATIENT_DETAILS_FETCH_STARTED";
export const PATIENT_DETAILS_FETCH_SUCCESS = "PATIENT_DETAILS_FETCH_SUCCESS";
export const PATIENT_DETAILS_FETCH_ERROR = "PATIENT_DETAILS_FETCH_ERROR";
export const FETCHING_SINGLE_PATIENT = "FETCHING_SINGLE_PATIENT";
export const SINGLE_PATIENT_DATA = "SINGLE_PATIENT_DATA";
export const SET_PATIENT_DETAILS = "SET_PATIENT_DETAILS";
export const SINGLE_PATIENT_FETCH_FAILED = "SINGLE_PATIENT_FETCH_FAILED";

// rest
export const ALLERGY_INTOLERANCE = "ALLERGY_INTOLERANCE";
export const CARE_PLAN = "CARE_PLAN";
export const CARE_TEAM = "CARE_TEAM";
export const CONDITION = "CONDITION";
export const OBSERVATION = "OBSERVATION";
export const DIAGNOSTIC_REPORT = "DIAGNOSTIC_REPORT";
export const ENCOUNTER = "ENCOUNTER";
export const GOAL = "GOAL";
export const IMMUNIZATION = "IMMUNIZATION";
export const MEDICATION_DISPENSE = "MEDICATION_DISPENSE";
export const MEDICATION_REQUEST = "MEDICATION_REQUEST";
export const PROCEDURE = "PROCEDURE";
export const PROCEDURE_REQUEST = "PROCEDURE_REQUEST";
export const FHIR_INIT = "FHIR_INIT";
export const FHIR_LOGIN = "FHIR_LOGIN";
export const FHIR_LOGIN_SUCCESS = "FHIR_LOGIN_SUCCESS";
export const FHIR_LOGIN_FAIL = "FHIR_LOGIN_FAIL";
export const FHIR_CLEAR_TOKEN = "FHIR_CLEAR_TOKEN";
export const FHIR_CLIENT = "FHIR_CLIENT";
export const FHIR_HSPC_AUTHORIZED = "FHIR_HSPC_AUTHORIZED";
export const FHIR_VERSION = "FHIR_VERSION";

// app
export const SET_APP_SCREEN = "SET_APP_SCREEN";
export const APP_RESET_STATE = "APP_RESET_STATE";
export const SET_APP = "SET_APP";
export const SET_TERMS_LOADING = "SET_TERMS_LOADING";
export const SET_TERMS = "SET_TERMS";
export const SET_ERROR_TO_SHOW = "SET_ERROR_TO_SHOW";


// apps
export const SET_SANDBOX_APPS = "SET_SANDBOX_APPS";
export const SET_SANDBOX_APPS_LOADING = "SET_SANDBOX_APPS_LOADING";
export const SET_SANDBOX_APP_LOADING = "SET_SANDBOX_APP_LOADING";
export const SET_SANDBOX_APPS_CREATING = "SET_SANDBOX_APPS_CREATING";
export const SET_SANDBOX_APPS_DELETING = "SET_SANDBOX_APPS_DELETING";
export const SET_CREATED_APP = "SET_CREATED_APP";

// launch scenarios
export const SET_LAUNCH_SCENARIOS_LOADING = "SET_LAUNCH_SCENARIOS_LOADING";
export const SET_LAUNCH_SCENARIOS = "SET_LAUNCH_SCENARIOS";
export const SET_LAUNCH_SCENARIOS_CREATING = "SET_LAUNCH_SCENARIOS_CREATING";
export const SET_LAUNCH_SCENARIOS_DELETING = "SET_LAUNCH_SCENARIOS_DELETING";

//Organization
export const SET_ORG_SCREEN = "SET_ORG_SCREEN";
export const ORG_RESET_STATE = "ORG_RESET_STATE";
export const SET_ORG = "SET_ORG";
export const SET_CREATED_ORG = "SET_CREATED_ORG";
export const SET_SANDBOX_ORGS_CREATING = "SET_SANDBOX_ORGS_CREATING";
// export const SET_TERMS_LOADING = "SET_TERMS_LOADING";
// export const SET_TERMS = "SET_TERMS";
// export const SET_ERROR_TO_SHOW = "SET_ERROR_TO_SHOW";
export const SET_CREATED_ORG_CSV = "SET_CREATED_ORG_CSV";
export const SET_SANDBOX_ORGS_CSV_CREATING = "SET_SANDBOX_ORGS_CSV_CREATING";
export const SET_ORG_CSV = "SET_ORG_CSV";

//Subscription list
export const SET_SUBSCRIPTION_LOADING = "SET_SUBSCRIPTION_LOADING";
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION"
// Notifications

export const FETCH_SUB_START = "FETCH_SUB_START";
export const FETCH_SUB_SUCCESS = "FETCH_SUB_SUCCESS";
export const FETCH_SUB_FAIL = "FETCH_SUB_FAIL";
export const FETCH_SUB = "FETCH_ASUB";


//audit logs

export const FETCH_AUDIT_LOGS_START = "FETCH_AUDIT_LOGS_START";
export const FETCH_AUDIT_LOGS_SUCCESS = "FETCH_AUDIT_LOGS_SUCCESS";
export const FETCH_AUDIT_LOGS_FAIL = "FETCH_AUDIT_LOGS_FAIL";
export const FETCH_AUDIT_LOGS = "FETCH_AUDIT_LOGS";

//metrics
export const FETCH_METRICS_WEEK_START = "FETCH_METRICS_WEEK_START";
export const FETCH_METRICS_WEEK_SUCCESS = "FETCH_METRICS_WEEK_SUCCESS";
export const FETCH_METRICS_WEEK_FAIL = "FETCH_METRICS_WEEK_FAIL";
export const FETCH_METRICS_WEEK = "FETCH_METRICS_WEEK";

export const FETCH_METRICS_WEEK_ERROR_START = "FETCH_METRICS_WEEK_ERROR_START";
export const FETCH_METRICS_WEEK_ERROR_SUCCESS = "FETCH_METRICS_WEEK_ERROR_SUCCESS";
export const FETCH_METRICS_WEEK_ERROR_FAIL = "FETCH_METRICS_WEEK_ERROR_FAIL";
export const FETCH_METRICS_ERROR_WEEK = "FETCH_METRICS_ERROR_WEEK";

export const FETCH_METRICS_WEEK_LATENCY_START = "FETCH_METRICS_WEEK_LATENCY_START";
export const FETCH_METRICS_WEEK_LATENCY_SUCCESS = "FETCH_METRICS_WEEK_LATENCY_SUCCESS";
export const FETCH_METRICS_WEEK_LATENCY_FAIL = "FETCH_METRICS_WEEK_LATENCY_FAIL";
export const FETCH_METRICS_LATENCY_WEEK = "FETCH_METRICS_LATENCY_WEEK";

export const FETCH_METRICS_MONTH_START = "FETCH_METRICS_MONTH_START";
export const FETCH_METRICS_MONTH_SUCCESS = "FETCH_METRICS_MONTH_SUCCESS";
export const FETCH_METRICS_MONTH_FAIL = "FETCH_METRICS_MONTH_FAIL";
export const FETCH_METRICS_MONTH = "FETCH_METRICS_MONTH";

export const FETCH_METRICS_MONTH_ERROR_START = "FETCH_METRICS_MONTH_ERROR_START";
export const FETCH_METRICS_MONTH_ERROR_SUCCESS = "FETCH_METRICS_MONTH_ERROR_SUCCESS";
export const FETCH_METRICS_MONTH_ERROR_FAIL = "FETCH_METRICS_MONTH_ERROR_FAIL";
export const FETCH_METRICS_ERROR_MONTH = "FETCH_METRICS_ERROR_MONTH";

export const FETCH_METRICS_MONTH_LATENCY_START = "FETCH_METRICS_MONTH_LATENCY_START";
export const FETCH_METRICS_MONTH_LATENCY_SUCCESS = "FETCH_METRICS_MONTH_LATENCY_SUCCESS";
export const FETCH_METRICS_MONTH_LATENCY_FAIL = "FETCH_METRICS_MONTH_LATENCY_FAIL";
export const FETCH_METRICS_LATENCY_MONTH = "FETCH_METRICS_LATENCY_MONTH";

export const METRICS_YEAR_START = "METRICS_YEAR_START";
export const METRICS_YEAR_SUCCESS = "METRICS_YEAR_SUCCESS";
export const METRICS_YEAR_FAIL = "METRICS_YEAR_FAIL";
export const METRICS_YEAR = "METRICS_YEAR";

export const METRICS_YEAR_ERROR_START = "METRICS_YEAR_ERROR_START";
export const METRICS_YEAR_ERROR_SUCCESS = "METRICS_YEAR_ERROR_SUCCESS";
export const METRICS_YEAR_ERROR_FAIL = "METRICS_YEAR_ERROR_FAIL";
export const METRICS_ERROR_YEAR = "METRICS_ERROR_YEAR";

export const METRICS_YEAR_LATENCY_START = "METRICS_YEAR_LATENCY_START";
export const METRICS_YEAR_LATENCY_SUCCESS = "METRICS_YEAR_LATENCY_SUCCESS";
export const METRICS_YEAR_LATENCY_FAIL = "METRICS_YEAR_LATENCY_FAIL";
export const METRICS_LATENCY_YEAR = "METRICS_LATENCY_YEAR";

export const METRICS_DEFAULT_START = "METRICS_DEFAULT_START";
export const METRICS_DEFAULT_SUCCESS = "METRICS_DEFAULT_SUCCESS";
export const METRICS_DEFAULT_FAIL = "METRICS_DEFAULT_FAIL";
export const METRICS_DEFAULT = "METRICS_DEFAULT";

export const METRICS_DEFAULT_ERR_START = "METRICS_DEFAULT_ERR_START";
export const METRICS_DEFAULT_ERR_SUCCESS = "METRICS_DEFAULT_ERR_SUCCESS";
export const METRICS_DEFAULT_ERR_FAIL = "METRICS_DEFAULT_ERR_FAIL";
export const METRICS_DEFAULT_ERR = "METRICS_DEFAULT_ERR";

export const METRICS_DEFAULT_LAT_START = "METRICS_DEFAULT_LAT_START";
export const METRICS_DEFAULT_LAT_SUCCESS = "METRICS_DEFAULT_LAT_SUCCESS";
export const METRICS_DEFAULT_LAT_FAIL = "METRICS_DEFAULT_LAT_FAIL";
export const METRICS_DEFAULT_LAT = "METRICS_DEFAULT_LAT";

// Provider Directory
export const FETCH_PROVIDER_DIR_START = "FETCH_PROVIDER_DIR_START";
export const FETCH_PROVIDER_DIR_SUCCESS = "FETCH_PROVIDER_DIR_SUCCESS";
export const FETCH_PROVIDER_DIR_FAIL = "FETCH_PROVIDER_DIR_FAIL";
export const FETCH_PROVIDER_DIR = "FETCH_PROVIDER_DIR";

//DocumentReference
export const FETCH_DOCUMENT_REF_START = "FETCH_DOCUMENT_REF_START";
export const FETCH_DOCUMENT_REF_SUCCESS = "FETCH_DOCUMENT_REF_SUCCESS";
export const FETCH_DOCUMENT_REF_FAIL = "FETCH_DOCUMENT_REF_FAIL";
export const FETCH_DOCUMENT_REF = "FETCH_DOCUMENT_REF";
//company profile
export const FETCH_COMPANY_START = "FETCH_COMPANY_START";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_FAIL = "FETCH_COMPANY_FAIL";
export const FETCH_COMPANY = "FETCH_COMPANY";

export const FETCH_COMPANYEDIT_START = "FETCH_COMPANYEDIT_START";
export const FETCH_COMPANYEDIT_SUCCESS = "FETCH_COMPANYEDIT_SUCCESS";
export const FETCH_COMPANYEDIT_FAIL = "FETCH_COMPANYEDIT_FAIL";
export const FETCH_COMPANYEDIT = "FETCH_COMPANYEDIT";
//vh summary || overview page details
export const FETCH_VHCOMPANY_START = "FETCH_VHCOMPANY_START";
export const FETCH_VHCOMPANY_SUCCESS = "FETCH_VHCOMPANY_SUCCESS";
export const FETCH_VHCOMPANY_FAIL = "FETCH_VHCOMPANY_FAIL";
export const FETCH_VHCOMPANY = "FETCH_VHCOMPANY";

export const FETCH_VHCOMPANYEDIT_START = "FETCH_VHCOMPANYEDIT_START";
export const FETCH_VHCOMPANYEDIT_SUCCESS = "FETCH_VHCOMPANYEDIT_SUCCESS";
export const FETCH_VHCOMPANYEDIT_FAIL = "FETCH_VHCOMPANYEDIT_FAIL";
export const FETCH_VHCOMPANYEDIT = "FETCH_VHCOMPANYEDIT";

//directory user 
export const FETCH_USERS_START = "FETCH_USERS_START";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";
export const FETCH_USERS = "FETCH_USERS";

export const FETCH_USERS_EDIT_START = "FETCH_USERS_EDIT_START";
export const FETCH_USERS_EDIT_SUCCESS = "FETCH_USERS_EDIT_SUCCESS";
export const FETCH_USERS_EDIT_FAIL = "FETCH_USERS_EDIT_FAIL";
export const FETCH_USERS_EDIT = "FETCH_USERS_EDIT";

export const CREATE_USERS_START = "CREATE_USERS_START";
export const CREATE_USERS_SUCCESS = "CREATE_USERS_SUCCESS";
export const CREATE_USERS_FAIL = "CREATE_USERS_FAIL";

export const FETCH_USER_ROLES_START = "FETCH_USER_ROLES_START";
export const FETCH_USER_ROLES_SUCCESS = "FETCH_USER_ROLES_SUCCESS";
export const FETCH_USER_ROLES_FAIL = "FETCH_USER_ROLES_FAIL";
export const FETCH_USER_ROLES = "FETCH_USER_ROLES";

export const FETCH_ORG_LIST_START = "FETCH_ORG_LIST_START";
export const FETCH_ORG_LIST_SUCCESS = "FETCH_ORG_LIST_SUCCESS";
export const FETCH_ORG_LIST_FAIL = "FETCH_ORG_LIST_FAIL";
export const FETCH_ORG_LIST = "FETCH_ORG_LIST";

//insurance
export const FETCH_INSURANCE_START = "FETCH_INSURANCE_START";
export const FETCH_INSURANCE_SUCCESS = "FETCH_INSURANCE_SUCCESS";
export const FETCH_INSURANCE_FAIL = "FETCH_INSURANCE_FAIL";
export const FETCH_INSURANCE_LIST = "FETCH_INSURANCE_LIST";

//pharmacy
export const FETCH_PHARMACY_START = "FETCH_PHARMACY_START";
export const FETCH_PHARMACY_SUCCESS = "FETCH_PHARMACY_SUCCESS";
export const FETCH_PHARMACY_FAIL = "FETCH_PHARMACY_FAIL";
export const FETCH_PHARMACY_LIST = "FETCH_PHARMACY_LIST";
// hooks
export const HOOKS_EXECUTING = "HOOKS_EXECUTING";
export const HOOKS_SET_CARDS = "HOOKS_SET_CARDS";
export const HOOKS_REMOVE_CARDS = "HOOKS_REMOVE_CARDS";
export const HOOKS_SET_SERVICES = "HOOKS_SET_SERVICES";
export const HOOKS_SERVICE_LOADING = "HOOKS_SERVICE_LOADING";
export const HOOKS_SET_CHANGED_SERVICES = "HOOKS_SET_CHANGED_SERVICES";