import * as types from "../../actions/action-creators/types";
import initialState from "./init";

export default function (state = initialState, action) {
    state = Object.assign({}, state);
    switch (action.type) {
        case types.FETCH_METRICS_WEEK_START:
            state.loading = true;
            state.metrics = [];
            break;
        case types.FETCH_METRICS_WEEK_SUCCESS:
            state.loading = false;
            break;
        case types.FETCH_METRICS_WEEK_FAIL:
            state.loading = false;
            state.error = action.payload.error;
            break;
        case types.FETCH_METRICS_WEEK:
            state.metrics = action.payload.metrics;
            break;
        case types.FETCH_METRICS_WEEK_ERROR_START:
            state.hasloading = true;
            state.metricserr = [];
            break;
        case types.FETCH_METRICS_WEEK_ERROR_SUCCESS:
            state.hasloading = false;
            break;
        case types.FETCH_METRICS_WEEK_ERROR_FAIL:
            state.hasloading = false;
            state.error = action.payload.error;
            break;
        case types.FETCH_METRICS_ERROR_WEEK:
            state.metricserr = action.payload.metricserr;
            break;
        case types.FETCH_METRICS_WEEK_LATENCY_START:
            state.fetchlat = true;
            state.metricslatency = [];
            break;
        case types.FETCH_METRICS_WEEK_LATENCY_SUCCESS:
            state.fetchlat = false;
            break;
        case types.FETCH_METRICS_WEEK_LATENCY_FAIL:
            state.fetchlat = false;
            state.error = action.payload.error;
            break;
        case types.FETCH_METRICS_LATENCY_WEEK:
            state.metricslatency = action.payload.metricslatency;
            break;
        case types.FETCH_METRICS_MONTH_START:
            state.load = true;
            state.metricsmonth = [];
            break;
        case types.FETCH_METRICS_MONTH_SUCCESS:
            state.load = false;
            break;
        case types.FETCH_METRICS_MONTH_FAIL:
            state.loading = false;
            state.error = action.payload.error;
            break;
        case types.FETCH_METRICS_MONTH:
            state.metricsmonth = action.payload.metricsmonth;
            break;
        case types.FETCH_METRICS_MONTH_ERROR_START:
            state.loadmontherr = true;
            state.metricsmontherr = [];
            break;
        case types.FETCH_METRICS_MONTH_ERROR_SUCCESS:
            state.loadmontherr = false;
            break;
        case types.FETCH_METRICS_MONTH_ERROR_FAIL:
            state.loadmontherr = false;
            state.error = action.payload.error;
            break;
        case types.FETCH_METRICS_ERROR_MONTH:
            state.metricsmontherr = action.payload.metricsmontherr;
            break;
        case types.FETCH_METRICS_MONTH_LATENCY_START:
            state.loadmonthlat = true;
            state.metricsmonthlat = [];
            break;
        case types.FETCH_METRICS_MONTH_LATENCY_SUCCESS:
            state.loadmonthlat = false;
            break;
        case types.FETCH_METRICS_MONTH_LATENCY_FAIL:
            state.loadmonthlat = false;
            state.error = action.payload.error;
            break;
        case types.FETCH_METRICS_LATENCY_MONTH:
            state.metricsmonthlat = action.payload.metricsmonthlat;
            break;
        case types.METRICS_YEAR_START:
            state.loadyear = true;
            state.yearmetrics = [];
            break;
        case types.METRICS_YEAR_SUCCESS:
            state.loadyear = false;
            break;
        case types.METRICS_YEAR_FAIL:
            state.loadyear = false;
            state.error = action.payload.error;
            break;
        case types.METRICS_YEAR:
            state.yearmetrics = action.payload.yearmetrics;
            break;
        case types.METRICS_YEAR_ERROR_START:
            state.yearloaderr = true;
            state.yearerr = [];
            break;
        case types.METRICS_YEAR_ERROR_SUCCESS:
            state.yearloaderr = false;
            break;
        case types.METRICS_YEAR_ERROR_FAIL:
            state.yearloaderr = false;
            state.error = action.payload.error;
            break;
        case types.METRICS_ERROR_YEAR:
            state.yearerr = action.payload.yearerr;
            break;
        case types.METRICS_YEAR_LATENCY_START:
            state.yearlatload = true;
            state.yearlat = [];
            break;
        case types.METRICS_YEAR_LATENCY_SUCCESS:
            state.yearlatload = false;
            break;
        case types.METRICS_YEAR_LATENCY_FAIL:
            state.yearlatload = false;
            state.error = action.payload.error;
            break;
        case types.METRICS_LATENCY_YEAR:
            state.yearlat = action.payload.yearlat;
            break;
        case types.METRICS_DEFAULT_START:
            state.loaddefault = true;
            state.matericsdefault = [];
            break;
        case types.METRICS_DEFAULT_SUCCESS:
            state.loaddefault = false;
            break;
        case types.METRICS_DEFAULT_FAIL:
            state.loaddefault = false;
            state.error = action.payload.error;
            break;
        case types.METRICS_DEFAULT:
            state.matericsdefault = action.payload.matericsdefault;
            break;
        case types.METRICS_DEFAULT_ERR_START:
            state.fetcherrdef = true;
            state.defaulterr = [];
            break;
        case types.METRICS_DEFAULT_ERR_SUCCESS:
            state.fetcherrdef = false;
            break;
        case types.METRICS_DEFAULT_ERR_FAIL:
            state.fetcherrdef = false;
            state.error = action.payload.error;
            break;
        case types.METRICS_DEFAULT_ERR:
            state.defaulterr = action.payload.defaulterr;
            break;
        case types.METRICS_DEFAULT_LAT_START:
            state.loaddef = true;
            state.deflat = [];
            break;
        case types.METRICS_DEFAULT_LAT_SUCCESS:
            state.loaddef = false;
            break;
        case types.METRICS_DEFAULT_LAT_FAIL:
            state.loaddef = false;
            state.error = action.payload.error;
            break;
        case types.METRICS_DEFAULT_LAT:
            state.deflat = action.payload.deflat;
            break;
        case "persist/REHYDRATE":
            state = action.payload ? action.payload.metrics : state;
            state.loading = false;
            state.hasloading = false;
            state.fetchlat = false;
            state.error = undefined;
            state.load = false;
            state.loadmontherr = false;
            state.loadmonthlat = false;
            state.loadyear = false;
            state.yearloaderr = false;
            state.yearlatload = false;
            state.loaddefault = false;
            state.fetcherrdef = false;
            state.loaddef = false;
            break;
    }
    return state;
}


