import React from "react";

const directoryRoutes = [
  {
    path: "/sync",
    component: React.lazy(() => import("./sync"))
  },
  {
    path: "/groups",
    component: React.lazy(() => import("./groups"))
  },
  {
    path: "/roles",
    component: React.lazy(() => import("./Roles/Roles"))
  },
  // {
  //   path: "/companyprofile",
  //   component: React.lazy(() => import("./companyprofile"))
  // },
  {
    path: "/companyprofile",
    component: React.lazy(() => import("./directorytabs"))
  },
  {
    path: "/editUserDetails",
    component: React.lazy(() => import("./editUserDetails"))
  },
  {
    path: "/users",
    component: React.lazy(() => import("./users"))
  },
  {
    path: "/policies",
    component: React.lazy(() => import("./policies"))
  }
];

export default directoryRoutes;