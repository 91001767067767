export default {
    fetch: false,
    Error: undefined,
    orguser: [],
    usercreate:false,
    usererr:undefined,
    Roles:[],
    fetchrole:false,
    vhlist:[],
    listload:false,
    orgedit:false
}