import * as types from "../../actions/action-creators/types";
import initialState from "./init";

export default (state = initialState, action) => {
    state = Object.assign({}, state);

    switch (action.type) {
        case types.SET_SUBSCRIPTION_LOADING:
            state.loading = action.payload.loading;
            break;
        case types.SET_SUBSCRIPTION:
            let data = action.payload.data;
            state.data = data;
            break;
        case types.FETCH_SUB_START:
            state.subload = true;
            state.sub = [];
            break;
        case types.FETCH_SUB_SUCCESS:
            state.subload = false;
            break;
        case types.FETCH_SUB_FAIL:
            state.subload = false;
            state.error = action.payload.error;
            break;
        case types.FETCH_SUB:
            state.sub = action.payload.sub;
            break;
        // case types.CREATE_SUB_START:
        //     state.creating = true;
        //     state.notification = [];
        //     break;
        // case types.CREATE_SUB_SUCCESS:
        //     state.creating = false;
        //     break;
        // case types.CREATE_SUB_FAIL:
        //     state.creating = false;
        //     state.err = action.payload.err;
        //     break;
        // case types.CREATE_SUB:
        //     state.notification = action.payload.notification;
        //     break;
        case "persist/REHYDRATE":
            state = action.payload ? action.payload.subscription : state;
            state.subload = false;
            state.error = undefined;
            // state.creating = false;
            // state.err = undefined
            break;
    }

    return state;
};

































// import * as types from "../../action-creators/types";
// import initialState from "./init";  

// export default (state = initialState, action) => {
//     state = Object.assign({}, state);

//     switch (action.type) {
//         case types.SET_SUBSCRIPTION_LOADING:
//             state.loading = action.payload.loading;
//             break;
        
//         case types.SET_SUBSCRIPTION:
//             let data = action.payload.data;
//             state.data = data;
//             break;
//     }

//     return state;
// };
