export default {
    fetching: false,
    Error: undefined,
    details: [],
      
    update:false,
    error:undefined,
    

}

