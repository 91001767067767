import React, { Component, useState } from "react";
import { withRouter } from "react-router-dom";
import { Icon, IconButton, MenuItem, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setLayoutSettings } from "app/redux/actions/LayoutActions";
import { logoutUser } from "app/redux/actions/UserActions";
import { signOut } from "app/redux/actions/action-creators/app";
import PropTypes from "prop-types";
import { MatxMenu, MatxSearchBox } from "matx";
import { isMdScreen, classList } from "utils";
import NotificationBar from "../SharedCompoents/NotificationBar";
import { Link } from "react-router-dom";
import ShoppingCart from "../SharedCompoents/ShoppingCart";
import HospitalSelector from "../SharedCompoents/HospitalSelector";
import { useHistory } from "react-router-dom";
import { Grid, ButtonGroup } from "@material-ui/core";
import Avatar from "react-avatar";
// import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import { AccordionSummary, Divider } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import Apps from "../SharedCompoents/apps";
import PersonIcon from "@material-ui/icons/Person";
//const History = useHistory()
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AppsIcon from "@mui/icons-material/Apps";
import { List, ListItem, Typography } from "@mui/material";

const styles = (theme) => ({
  topbar: {
    "& .topbar-hold": {
      backgroundColor: theme.palette.primary.main,
      height: "80px",
      "&.fixed": {
        boxShadow: theme.shadows[8],
        height: "64px",
      },
    },
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    minWidth: 185,
  },

  gridStyle: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "16px", // Adjust the gap as needed
  },

  listItemStyle: {
    // Apply the CSS of the first list item to all items
    backgroundColor: "lightgray", // Change this to your desired CSS properties
    padding: "16px",
    textAlign: "center",
  },
});
const ExpansionPanelSummary = withStyles({
  root: {
    display: "flex",
    padding: "0 24px 0 24px",
    height: "36px",
    minHeight: "36px",
    "&$expanded": {
      minHeight: "36px",
    },
  },
  expandIcon: {
    padding: 3,
  },
  content: {
    "&$expanded": {},
  },

  expanded: { backgroundColor: "#f1f1f1" },
})(AccordionSummary);
class Layout1Topbar extends Component {
  state = { anchorEl: null };
  state = { menuApps: null };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleApps = (event) => {
    this.setState({ menuApps: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ menuApps: null });
  };
  updateSidebarMode = (sidebarSettings) => {
    let { settings, setLayoutSettings } = this.props;

    setLayoutSettings({
      ...settings,
      layout1Settings: {
        ...settings.layout1Settings,
        leftSidebar: {
          ...settings.layout1Settings.leftSidebar,
          ...sidebarSettings,
        },
      },
    });
  };

  handleSidebarToggle = () => {
    let { settings } = this.props;
    let { layout1Settings } = settings;

    let mode;
    if (isMdScreen()) {
      mode = layout1Settings.leftSidebar.mode === "close" ? "mobile" : "close";
    } else {
      mode = layout1Settings.leftSidebar.mode === "full" ? "close" : "full";
    }
    this.updateSidebarMode({ mode });
  };

  handleSignOut = () => {
    // this.props.logoutUser();
    this.props.signOut();
    this.setState({ anchorEl: null });
    this.setState({ menuApps: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { menuApps } = this.state;

    let { classes, fixed, role } = this.props;
    // let virtualhospital = window.location.pathname !== '/virtualhospitals'
    let sandboxId = localStorage.getItem("sandboxId");
    //let path= window.location.pathname;
    let path = this.props.history.location.pathname;

    let users = JSON.parse(localStorage.getItem("oauthUser"));
    return (
      <div className={`topbar ${classes.topbar}`}>
        <div className={classList({ "topbar-hold": true, fixed: fixed })}>
          <div className="flex justify-between items-center h-full">
            <div className="flex">
              <IconButton
                onClick={this.handleSidebarToggle}
                className="hide-on-pc"
              >
                <Icon>menu</Icon>
              </IconButton>

              <div className="hide-on-mobile">
                {/* <IconButton>
                  <Icon>mail_outline</Icon>
                </IconButton>

                <IconButton>
                  <Icon>web_asset</Icon>
                </IconButton>

                <IconButton>
                  <Icon>star_outline</Icon>
                </IconButton> */}
              </div>
              <Apps />
              {/* {path.startsWith('/' + sandboxId) && path !== '/home' ?
                <Apps />

                // <Button onClick={() => this.props.history.push(`/virtualhospitals`)}>See all Virtual Hospitals</Button>
                : ''} */}
            </div>
            <div className="hide-on-mobile">
              <span style={{ color: "white", fontWeight: "900" }}>
                {users === null
                  ? ""
                  : `Welcome to ${
                      users.sbmUserId
                        .substring(users.sbmUserId.lastIndexOf("@") + 1)
                        .split(".")[0]
                    } organization`}
              </span>
            </div>
            <div className="flex items-center ">
              <AppsIcon
                sx={{ color: "#ffff", cursor: "pointer" }}
                onClick={this.handleApps}
              />
              ;
              <Menu
                id="simple-menu"
                anchorEl={menuApps}
                open={Boolean(menuApps)}
                onClose={this.handleClose}
                style={{ marginTop: "48px" }}
              >
                <List>
                  <Grid container spacing={2} style={styles.gridStyle}>
                    <Grid item xs={2}>
                      <ListItem>
                        <IconButton onClick={this.handleClick}>
                          <img
                            src="/assets/images/Kare MD.svg"
                            alt="My Icon"
                            height={55}
                            width={55}
                          />
                        </IconButton>
                      </ListItem>
                    </Grid>
                    <Grid item xs={2}>
                      <ListItem>
                        <IconButton
                          onClick={this.handleClick}
                          sx={{ cursor: "pointer" }}
                        >
                          <img
                            src="/assets/images/Kare Portal.svg"
                            alt="My Icon"
                            height={85}
                            width={85}
                          />
                        </IconButton>
                      </ListItem>
                    </Grid>
                    <Grid item xs={2}>
                      <ListItem>
                        <IconButton onClick={this.handleClick}>
                          <img
                            src="/assets/images/Kare Practice.svg"
                            alt="My Icon"
                            height={85}
                            width={85}
                          />
                        </IconButton>
                      </ListItem>
                    </Grid>
                    <Grid item xs={2}>
                      <ListItem>
                        <IconButton onClick={this.handleClick}>
                          <img
                            src="/assets/images/Kare Intelligence.svg"
                            alt="My Icon"
                            height={100}
                            width={100}
                          />
                        </IconButton>
                      </ListItem>
                    </Grid>
                  </Grid>
                </List>
              </Menu>
              <MatxSearchBox />
              <NotificationBar />
              <Link to="/video">
                <Icon style={{ color: "white" }}>videocam</Icon>
              </Link>
              {/* <ShoppingCart></ShoppingCart> */}
              <div>
                <div
                  className="inline-block "
                  aria-owns={anchorEl ? "simple-menu" : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                >
                  <div className="aaa hide-on-mobile">
                    <ListItemText
                      primary={
                        users === null ? (
                          ""
                        ) : (
                          <span style={{ fontSize: "13px", color: "white" }}>
                            {users.sbmUserId}
                          </span>
                        )
                      }
                      secondary={
                        <span style={{ fontSize: "10px", float: "right" }}>
                          {users === null ? "" : users.roles && users.roles[0]}
                          {/* Super Admin */}
                          {/* {role == null ? '' :
                            role.roles && role.roles.map((option, i) => option)} */}
                        </span>
                      }
                    />
                    <div>
                      <img
                        className="mx-2 align-middle circular-image-small1 cursor-pointer"
                        src="/assets/images/pic.jpeg"
                        alt="user"
                      />
                    </div>
                    <span
                    // style={{ color: 'white' }}
                    >
                      {/* <ListItemText primary={users === null ? '' : <span style={{ fontSize: '13px', color: 'white' }}>{users.sbmUserId}</span>} secondary={<span style={{ fontSize: '10px', }}>Super Admin</span>} /> */}
                    </span>
                  </div>
                  <div className="show-on-mobile">
                    <img
                      className="mx-2 align-middle circular-image-small1 cursor-pointer"
                      src="/assets/images/pic.jpeg"
                      alt="user"
                    />
                  </div>
                </div>

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                  style={{ marginTop: "48px" }}
                >
                  <div style={{ paddingRight: "7px", paddingLeft: "7px" }}>
                    <Grid
                      container
                      direction="row"
                      // justify="center"

                      // alignItems="center"
                      style={{ width: "400px" }}
                    >
                      <Grid item xs={12}>
                        <div style={{ display: "flex" }}>
                          <div>
                            <Avatar
                              color={Avatar.getRandomColor("sitebase", [
                                "red",
                                "green",
                                "blue",
                              ])}
                              src="/assets/images/pic.jpeg"
                              name="Admin"
                              className="inline-block"
                              size="88"
                              round={true}
                              style={{
                                border: "1px solid #0055B4",
                                height: "90px",
                                marginLeft: "12px",
                              }}
                            />
                          </div>
                          <div
                          // style={{ marginTop: '22px', textAlign: 'center', }}
                          >
                            <ListItemText
                              style={{ marginTop: "20px", marginLeft: "15px" }}
                              primary={
                                users === null ? (
                                  ""
                                ) : (
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500px",
                                      color: "black",
                                    }}
                                  >
                                    {users.sbmUserId}
                                  </span>
                                )
                              }
                              secondary={
                                <span
                                  style={{ fontSize: "12px", color: "#6979AE" }}
                                >
                                  {users === null
                                    ? ""
                                    : users.roles && users.roles[0]}
                                </span>
                              }
                            />
                          </div>
                        </div>
                        {/* <div style={{ textAlign: 'center', marginTop: '22px' }}>
                          <Avatar
                            color={Avatar.getRandomColor('sitebase', ['red', 'green', 'blue'])}
                            src="/assets/images/pic.jpeg"
                            name="Admin"
                            size="98" round={true}
                            style={{ border: '1px solid #0055B4', height: '100px' }}
                          />
                        </div>
                        <div style={{ marginTop: '22px', textAlign: 'center', }}>
                          <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '15px' }}>&nbsp;
                      {users === null ? '' : users.name}
                          </span>
                        </div>
                        <p style={{ textAlign: 'center', color: '#979797', fontSize: '14px' }}>super admin</p> */}
                        <Divider
                          style={{
                            backgroundColor: "#C8D2DC",
                            marginTop: "7px",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div
                        //style={{ marginTop: '30px' }}
                        >
                          <ListItem
                            button
                            onClick={() =>
                              window.open(
                                `${window._env_.redirectUri}/profile`,
                                "_blank"
                              )
                            }
                          >
                            {/* <ListItemIcon> */}
                            <i
                              className="fa fa-user"
                              style={{
                                color: "#A8AFB9",
                                fontSize: "21px",
                                marginRight: "16px",
                              }}
                            ></i>
                            {/* <PersonIcon color='secondary'/> */}
                            {/* </ListItemIcon> */}
                            <ListItemText
                              primary="My Profile"
                              style={{ color: "#112558" }}
                            />
                          </ListItem>
                          <ListItem
                            button
                            style={{ marginTop: "-10px" }}
                            onClick={() =>
                              window.open(
                                `${window._env_.redirectUri}/profile`,
                                "_blank"
                              )
                            }
                          >
                            <i
                              className="fa fa-cog"
                              style={{
                                color: "#A8AFB9",
                                fontSize: "21px",
                                marginRight: "16px",
                              }}
                            ></i>
                            <ListItemText
                              primary="Manage My Account"
                              style={{ color: "#112558" }}
                            />
                          </ListItem>
                          <ListItem
                            button
                            style={{ marginTop: "-10px" }}
                            onClick={() =>
                              window.open(
                                `${window._env_.redirectUri}/profile`,
                                "_blank"
                              )
                            }
                          >
                            <i
                              className="fa fa-wrench"
                              style={{
                                color: "#A8AFB9",
                                fontSize: "21px",
                                marginRight: "16px",
                              }}
                            ></i>
                            <ListItemText
                              primary="My Settings"
                              style={{ color: "#112558" }}
                            />
                          </ListItem>
                          <ListItem
                            button
                            onClick={this.handleClose}
                            style={{ marginTop: "-10px" }}
                          >
                            <Link className={classes.menuItem} to="/help">
                              <i
                                className="fa fa-question-circle"
                                style={{
                                  color: "#A8AFB9",
                                  fontSize: "21px",
                                  marginRight: "16px",
                                }}
                              ></i>
                              <ListItemText
                                primary="Help"
                                style={{ color: "#112558" }}
                              />
                            </Link>
                          </ListItem>
                        </div>
                        <Divider
                          style={{
                            backgroundColor: "#C8D2DC",
                            marginTop: "7px",
                          }}
                        />
                        <ListItemText
                          primary="Roles"
                          style={{
                            color: "#112558",
                            marginLeft: "16px",
                            marginTop: "5px",
                          }}
                        />
                        <div>
                          {role == null
                            ? ""
                            : role.roles &&
                              role.roles.map((option, i) => (
                                <div
                                  key={`Key${i}`}
                                  onClick={() => {
                                    localStorage.setItem("Role", option);
                                  }}
                                >
                                  <ListItem button onClick={this.handleClose}>
                                    <i
                                      className="fa fa-user"
                                      style={{
                                        color: "#A8AFB9",
                                        fontSize: "21px",
                                        marginRight: "16px",
                                      }}
                                    ></i>
                                    {/* //var y = x.map(s => s.slice(1)); */}
                                    {/* <ListItemText primary={option.slice(5).replace(/_/g, ' ')} style={{ color: '#112558' }} /> */}
                                    <ListItemText
                                      primary={option}
                                      style={{ color: "#112558" }}
                                    />
                                    {/* <ListItemText primary={<span style={{ fontSize: '14px', color: 'black' }}>{option}</span>} /> */}
                                  </ListItem>
                                </div>
                              ))}
                        </div>
                        <Divider
                          style={{
                            backgroundColor: "#C8D2DC",
                            marginTop: "7px",
                          }}
                        />
                        <ListItem button onClick={this.handleSignOut}>
                          <i
                            className="fa fa-sign-out"
                            style={{
                              color: "#A8AFB9",
                              fontSize: "21px",
                              marginRight: "16px",
                            }}
                          ></i>
                          <ListItemText
                            primary="Sign Out"
                            style={{ color: "#112558" }}
                          />
                        </ListItem>
                      </Grid>
                      {/* <Grid item xs={6}>
                        <div style={{ marginTop: '30px' }} >

                          <Accordion  >
                            <ExpansionPanelSummary
                              style={{ border: '1px solid rgba(63, 81, 181, 0.5)', borderRadius: '4px' }}
                              expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <span style={{ color: 'black' }}>Switch Role</span>
                            </ExpansionPanelSummary>
                            <Divider />
                            <div>
                              <div>
                                {role == null ? '' :
                                  role.roles && role.roles.map((option, i) =>
                                    <div key={`Key${i}`} onClick={() => { localStorage.setItem("Role", option) }}>
                                      <ListItem button onClick={this.handleClose}>

                                        <ListItemText primary={<span style={{ fontSize: '14px', color: 'black' }}>{option}</span>} />
                                      </ListItem>
                                    </div>
                                    
                                  )}
                              </div>
                             

                            </div>

                          </Accordion>
                        </div>
                      </Grid>
                      <Grid item xs={6} >
                        <div style={{ marginTop: '30px' }}>
                          <Button
                            variant='outlined'
                            style={{ color: 'rgb(0, 85, 180)',border: '1px solid rgba(63, 81, 181, 0.5)' }}
                            onClick={this.handleSignOut}
                            fullWidth color='primary'>Sign Out</Button>
                        </div>
                      </Grid> */}
                    </Grid>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Layout1Topbar.propTypes = {
  setLayoutSettings: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  setLayoutSettings: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  settings: state.layout.settings,
  role: state.users.oauthUser,
});

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, { setLayoutSettings, signOut })(Layout1Topbar)
  )
);
