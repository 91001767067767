import SignUp from "./SignUp";
import SignIn from "./SignIn";
import NotFound from "./NotFound";
import ForgotPassword from "./ForgotPassword";
import Afterauth from "./afterauth";
import Start from "./Start"
import VirtualHospital from "../Virtualhospital/VirtualHospital";
let sandboxId = localStorage.getItem('sandboxId');
const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false
    },
    leftSidebar: {
      show: false,
      mode: "close"
    }
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false
    },
    navbar: { show: false }
  },
  secondarySidebar: { show: false },
  footer: { show: false }
};

const sessionRoutes = [
  // {
  //   path: "/session/signup",
  //   component: SignUp,
  //   settings
  // },
  // {
  //   path: "/session/signin",
  //   component: SignIn,
  //   settings
  // },
  // {
     
  //   path: "/virtualhospitals",
  //   component: VirtualHospital,
  //    settings
  // },
  // {
  //   path: "/session/forgot-password",
  //   component: ForgotPassword,
  //   settings
  // },
  // {
  //   path: '/' + sandboxId +"/session/404",
  //   component: NotFound,
  //   settings
  // },
  {
    path: "/after-auth",
    component: Afterauth,
    settings
  },
  {
    path: "/start",
    component: Start,
    settings
  },
 
 
];

export default sessionRoutes;
