import * as types from "../../actions/action-creators/types";
import initialState from "./init";

export default function (state = initialState, action) {
    state = Object.assign({}, state);
    switch (action.type) {
        case types.FETCH_VHCOMPANY_START:
            state.fetching = true;
            state.details = [];
            state.snackbar=false;
            break;
        case types.FETCH_VHCOMPANY_SUCCESS:
            state.fetching = false;
            break;
        case types.FETCH_VHCOMPANY_FAIL:
            state.fetching = false;
            state.Error = action.payload.error;
            break;
        case types.FETCH_VHCOMPANY:
            state.details = action.payload.details;
            break;
        case types.FETCH_VHCOMPANYEDIT_START:
            state.update = true;
            state.snackbar=false;
            break;
        case types.FETCH_VHCOMPANYEDIT_SUCCESS:
            state.update = false;
            state.snackbar=true;
            break;
        case types.FETCH_VHCOMPANYEDIT_FAIL:
            state.update = false;
            state.error = action.payload.error;
            break;
        case "persist/REHYDRATE":
            state = action.payload ? action.payload.summary : state;
            state.fetching = false;
            state.Error = undefined;
            state.update = false;
            state.snackbar=false;
            state.error = undefined;
            break;
    }
    return state;
}
