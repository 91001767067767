import React, { Component } from 'react';
import { afterFhirAuth } from '../../redux/actions/action-creators'
///'../../../redux/action-creators';
import { connect } from 'react-redux';
//import withErrorHandler from 'virtual-manager-lib/hoc/withErrorHandler';
import { bindActionCreators } from 'redux';
import Load from './load'
class AfterAuth extends Component {

    componentDidMount() {
        let url = this.props.location;
        this.props.afterFhirAuth(url);
        
       // console.log(this.props.config)

    }

    componentDidUpdate() {
        this.props.user.sbmUserId && this.props.history.push('/home');

    }
  
    render() {
        return (
            <div >
                {/* <Load/> */}
               
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.users.oauthUser,
        config: state.config.xsettings.data.sandboxManager
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ afterFhirAuth }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(AfterAuth)
