export default {
    token: null,
    loading: false,
    error: null,
    hspcAuthorized : false,
    fhirClient: null,
    fhirVersion: null,
   // scope: 'smart/orchestrate_launch user/*.* profile openid vh.write vh.invite user.cheif',
    scope: 'smart/orchestrate_launch user/*.* profile openid vh.write vh.invite user.cheif email vh.delete phone address',
   //scope: 'profile openid user.cheif email phone address',
}
