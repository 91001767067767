import * as React from "react";
import { Grid, Stack } from "@mui/joy";
import EditIcon from "@mui/icons-material/Edit";
import  PropTypes  from "prop-types";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
export default function Summary(props) {
  const {
    formFields,
    formValues,
    title,
    editfun,
    uploadImage,
    DeleteImage,
    image,
    tagline,
  } = props;
  const rows = [];
  for (let i = 0; i < formFields?.length; i += 3) {
    const fieldsInRow = formFields?.slice(i, i + 3);
    rows.push(fieldsInRow);
  }

  return (
    <div style={{ marginTop: "25px" }}>
      <Grid container spacing={1} sx={{ flexGrow: 1 }}>
        {tagline != "User-Details" && (
          <Grid xs={3}>
            <div style={{ position: "relative", display: "inline-block" }}>
              <img
                src={image ? image : "https://dummyimage.com/300"}
                alt="Image"
                style={{
                  height:
                    tagline === "Organization profile" ? "100px" : "150px",
                  paddingLeft: tagline != "Organization profile" ? "80px" : "0",
                }}
              />
              {/* {tagline === "Organization profile" && (
                <React.Fragment>
                  <label
                    htmlFor="aa"
                    style={{ position: "absolute", bottom: "0", right: "0" }}
                  >
                    <img
                      onClick={() => uploadImage(true)}
                      style={{ position: "sticky" }}
                      src="/assets/images/Camera.svg"
                    />
                  </label>
                  <div
                    onClick={DeleteImage}
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      cursor: "pointer",
                      backgroundColor: "white",
                      borderRadius: "50%",
                      padding: "4px",
                    }}
                  >
                    X
                  </div>
                </React.Fragment>
              )} */}
            </div>
            <div>
              {tagline === "Organization profile" && (
                <Stack direction="row" spacing={2} justifyContent={"center"} sx={{paddingTop:"15px"}}>
                  <Button
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon size="small"/>}
                    size="small"
                    onClick={() => uploadImage(true)}
                  >
                    Upload 
                  </Button>
                  <Button variant="outlined" disabled={image?false:true}  startIcon={<DeleteIcon />} size={"small"} onClick={DeleteImage}>
                    Remove
                  </Button>
                </Stack>
              )}
            </div>
          </Grid>
        )}
        {tagline == "User-Details" && (
          <Grid xs={2}>
            <div style={{ position: "relative", display: "inline-block" }}>
              <img
                src={image ? image : "https://dummyimage.com/300"}
                alt="Girl in a jacket"
                style={{ height: "180px" }}
              />
              <label
                htmlFor="aa"
                style={{ position: "absolute", bottom: "0", right: "0" }}
              >
                <img
                  onClick={() => uploadImage(true)}
                  style={{ position: "sticky" }}
                  src="/assets/images/Camera.svg"
                />
              </label>
            </div>
          </Grid>
        )}
        <Grid xs={8}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <div
              style={{ fontSize: "24px", fontWeight: "600", color: "#0055b4" }}
            >
              {title ? title?.toUpperCase() : ""}
            </div>
            <div onClick={editfun} style={{ cursor: "pointer" }}>
              <EditIcon />
            </div>
          </Stack>
          <p style={{ fontWeight: "800", color: "grey" }}>{tagline}</p>
          {/* <Grid
            xs={8}
            sx={{
              borderBottom: "2px solid #c9c1c152",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          > */}
          {/* {props?.service === "service" && (
              <div style={{ marginBottom: "10px" }}>
                <span
                  style={{
                    backgroundColor: "#0b6bcb",
                    color: "white",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  {" "}
                  Details{" "}
                </span>{" "}
                {/* &nbsp; | Services | Practitioners */}
          {/* </div> */}
          {/* )} */}
          {/* {props?.service === "practice" && (
              <div style={{ marginBottom: "10px" }}>
                <span
                  style={{
                    backgroundColor: "#0b6bcb",
                    color: "white",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  {" "}
                  summary{" "}
                </span>{" "}
                &nbsp; | Practitioners | Teams
              </div>
            )} */}
          {/* {props?.service === "teams" && (
              <div style={{ marginBottom: "10px" }}>
                <span
                  style={{
                    backgroundColor: "#0b6bcb",
                    color: "white",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  {" "}
                  summary{" "}
                </span>{" "}
                &nbsp; | Patients | Practitioners
              </div>
            )}  */}
          {/* </Grid> */}
          {/* <Grid xs={12}> */}
          {/* <div
              style={{ fontSize: "18px", fontWeight: "600", color: "#0055b4" }}
            >
              Details
            </div> */}
          {/* </Grid> */}
          <div>
            <Grid
              container
              spacing={2}
              sx={{
                borderTop: "2px solid #c9c1c152",
                // marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              {rows?.map((rowFields, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  {rowFields?.map((field) => (
                    <Grid item xs={12} sm={4} width={120} key={field.id}>
                      <div className="form-field">
                        <span className="form-label">{field.label}:&nbsp;</span>
                        <span className="form-value">
                          <strong>{formValues[field.value] || ""}</strong>
                        </span>
                      </div>
                    </Grid>
                  ))}
                </React.Fragment>
              ))}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

Summary.propTypes = {
  formFields: PropTypes.array,
  formValues: PropTypes.any,
  editfun: PropTypes.any,
  uploadImage: PropTypes.any,
  image: PropTypes.any,
  tagline: PropTypes.any,
  DeleteImage: PropTypes.any,
};
