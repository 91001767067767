import React from "react";
//let sandboxId = 'apollo'
const mapRoutes = [
  {
   // path: '/' + sandboxId + '/map',
     path: "/:sandboxId/apps",
    component: React.lazy(() => import("./Apps"))
  }
];

export default mapRoutes;
