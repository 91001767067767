import * as actionTypes from "./types";
import API from '../../../lib/api'
export function companyFetchStarted() {
    return {
        type: actionTypes.FETCH_COMPANY_START
    }
}
export function companyFetchSuccess() {
    return {
        type: actionTypes.FETCH_COMPANY_SUCCESS
    }
}

export function companyFetchError(error) {
    return {
        type: actionTypes.FETCH_COMPANY_FAIL,
        payload: { error }
    }
}
export function companyFetch(details) {
    return {
        type: actionTypes.FETCH_COMPANY,
        payload: { details }
    }
}


export function loadcompanydetails() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            let users = JSON.parse(localStorage.getItem('sbmUserId'))
            let domain = users.substring(users.lastIndexOf("@") + 1);
            dispatch(companyFetchStarted());
            let url = `${window._env_.sandboxManagerApiUrl}/organization/${domain.split('.')[0]}`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(companyFetchSuccess());
                    dispatch(companyFetch(res));
                    console.log(res)

                })
                .catch(error => dispatch(companyFetchError(error)));


        }
    }
}

export function companyeditFetchStarted() {
    return {
        type: actionTypes.FETCH_COMPANYEDIT_START
    }
}
export function companyeditFetchSuccess() {
    return {
        type: actionTypes.FETCH_COMPANYEDIT_SUCCESS
    }
}

export function companyEDITFetchError(error) {
    return {
        type: actionTypes.FETCH_COMPANYEDIT_FAIL,
        payload: { error }
    }
}
export function editCompanydetails(val) {
    let data =
    {
        active: val.active,
        adress: val.adress,
        adressLine2: val.adressLine2,
        aliasName: val.aliasName,
        city: val.city,
        code: val.code,
        country: val.country,
        createdTimestamp: val.createdTimestamp,
        created_by: val.created_by,
        description: val.description,
        email: val.email,
        fax: val.fax,
        id: val.id,
        name: val.name,
        organizationType: val.organizationType,
        orgnizationEndpoint: val.orgnizationEndpoint,
        phone: val.phone,
        sandboxId: val.sandboxId,
        state: val.state,
        zip: val.zip,
        ein_number: val.ein_number,
        profile_pic: val.profile_pic
    }

    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            console.log(data)
            let users = JSON.parse(localStorage.getItem('sbmUserId'))
            let domain = users.substring(users.lastIndexOf("@") + 1);
            //let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(companyeditFetchStarted());
            let url = `${window._env_.sandboxManagerApiUrl}/organization/${domain.split('.')[0]}`
            API.put(url, data, dispatch, true)

                .finally(() => setTimeout(() => {
                    dispatch(companyeditFetchSuccess())
                }, 550))

                .catch(error => dispatch(companyEDITFetchError(error)));


        }
    }
}






