import ReactECharts from 'echarts-for-react';

export const EchartTheme = (MuiTheme) => ({
  backgroundColor: MuiTheme.palette.background.paper,
  // Global palette:
  color: [
    // ...
  ],
  series: [
    {
      type: "bar",
      // ...
    },
    {
      type: "pie",
      // ...
    },
    {
      type: "line",
      color: new ReactECharts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: "#83bff6" },
        { offset: 0.5, color: "#188df0" },
        { offset: 1, color: "#188df0" },
      ]),
    },
  ],
});
