import * as actionTypes from "./types";
import API from '../../../lib/api'
export function insuranceFetchStarted() {
    return {
        type: actionTypes.FETCH_INSURANCE_START
    }
}
export function insuranceFetchSuccess() {
    return {
        type: actionTypes.FETCH_INSURANCE_SUCCESS
    }
}
export function insuranceFetchFail() {
    return {
        type: actionTypes.FETCH_INSURANCE_FAIL
    }
}
export function insuranceFetchlist(insur) {
    return {
        type: actionTypes.FETCH_INSURANCE_LIST,
        payload: { insur }
    }
}

export function loadinsurance() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(insuranceFetchStarted());
            let url = 'https://fhir-api-r3.collabkare.com/collabnotes/open/Coverage'
            API.get(url, dispatch)
                .then(res => {
                    dispatch(insuranceFetchlist(res));
                    dispatch(insuranceFetchSuccess());
                    console.log(state)
                })
                .catch(dispatch(insuranceFetchFail()));


        }
    }
}