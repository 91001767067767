import React, { Component } from 'react';


import { withRouter } from 'react-router-dom';
import { CircularProgress, Dialog, IconButton } from '@material-ui/core';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import Auxilary from '../SharedCompoents/Auxilary/Auxilary';
import { connect } from 'react-redux';
import HospitalSelector from "./HospitalSelector"

class Layout extends Component {

    constructor(props) {
        super(props);

        let showNotification = !sessionStorage.getItem(`showSandboxNotification-${sessionStorage.sandboxId}`);

        this.state = {
            sideBarOpened: false,
            showNotification,
            drawerOpened: false
        };
    }



    render() {
        // let role = localStorage.getItem('Role')
        let path = this.props.history.location.pathname;
        let isRoot = path === '/';
        let isDashboard = path === '/virtualhospitals';
        let adminhome = path === '/home';

        let isLaunch = this.props.path === '/launchApp';


        return isLaunch
            ? <Auxilary>
                {this.props.children}
            </Auxilary>
            : !isRoot
                ? <Auxilary>
                    <Dialog open={this.props.isSandboxCreating}>
                        <div style={{ textAlign: 'center', margin: 'inherit' }}>
                            <p>
                                Creating Virtual Hospital
                        </p>
                            <CircularProgress size={80} thickness={5} />
                        </div>
                    </Dialog>

                    {this.props.sandbox &&
                        <HospitalSelector {...this.props} />
                    }

                    <main>
                        {this.showExpirationMessageBanner()}
                        {this.props.children}
                    </main>



                </Auxilary>
                :
                <Auxilary>
                    <main>
                        {this.props.children}
                    </main>
                </Auxilary>
    }


    showExpirationMessageBanner() {
        if (this.props.sandbox) {
            const exprDay = new Date(this.props.sandbox.expirationDate);
            const exprMessage = this.props.sandbox.expirationMessage;
            const today = new Date();
            if (exprDay != null) {
                if (exprDay >= today && this.state.showNotification) {
                    if (exprMessage.indexOf('</a>') != -1) {
                        let firstPart = exprMessage.split('<a')[0];
                        let hrefIndex = exprMessage.indexOf('href');
                        let firstAIndex = exprMessage.indexOf('>')
                        let secondAIndex = exprMessage.indexOf('</a>');
                        let linkUrl = exprMessage.slice(hrefIndex + 6, firstAIndex - 1);
                        let linkMessage = exprMessage.slice(firstAIndex + 1, secondAIndex);
                        let lastPart = exprMessage.slice(secondAIndex + 4, this.props.sandbox.expirationMessage.length);
                        return <header className="message-bar"><span style={{ maxWidth: '90%', marginRight: '10%', display: 'inline-block' }}> {firstPart}
                            <a target="_blank" href={linkUrl}>{linkMessage}</a> {lastPart} </span>
                            <IconButton onClick={this.removeNotification}><CloseIcon /></IconButton> </header>;
                    } else {
                        return <header className="message-bar">
                            <span style={{ maxWidth: '90%', marginRight: '10%', display: 'inline-block' }}>{exprMessage}</span>
                            <IconButton onClick={this.removeNotification}><CloseIcon /></IconButton> </header>;
                    }
                }
                else {
                    return null;
                }
            } else {
                return null;
            }
        }
    };

    removeNotification = () => {
        sessionStorage.setItem(`showSandboxNotification-${sessionStorage.sandboxId}`, true);
        this.setState({ showNotification: false });
    };
}

const mapStateToProps = state => {
    let sandboxApiUrl = "https://manager-api.collabkare.com";
    sandboxApiUrl && sandboxApiUrl.indexOf('//') >= 0 && (sandboxApiUrl = sandboxApiUrl.split('//')[1]);

    return {
        sandbox: state.sandbox.sandboxes.find(i => i.sandboxId === sessionStorage.sandboxId),
        sandboxes: state.sandbox.sandboxes,
        defaultUser: state.sandbox.defaultUser,
        isSandboxCreating: state.sandbox.creatingSandbox,
        user: state.users.oauthUser,
        sandboxApiUrl,
        screen: state.app.screen,
        terms: state.app.terms,
        invitations: state.sandbox.userInvites,
        loginInfo: state.sandbox.loginInfo,
        notifications: state.sandbox.notifications
    }
};

export default connect(mapStateToProps)(withRouter(Layout));