import React from "react";

const fhirAccessRoutes = [
  {
    path: "/:sandboxId/consents",
    component: React.lazy(() => import("./Consents"))
  },
  
];

export default fhirAccessRoutes;