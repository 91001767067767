import * as types from "../../actions/action-creators/types";
import initialState from "./init";

export default (state = initialState, action) => {
    state = Object.assign({}, state);

    switch (action.type) {
        case types.FETCH_DOCUMENT_REF_START:
            state.creating = true;
            state.documentref = [];
            break;
        case types.FETCH_DOCUMENT_REF_SUCCESS:
            state.creating = false;
            break;
        case types.FETCH_DOCUMENT_REF_FAIL:
            state.creating = false;
            state.err = action.payload.err;
            break;
        case types.FETCH_DOCUMENT_REF:
            state.documentref = action.payload.documentref;
            break;
        case "persist/REHYDRATE":
            state = action.payload ? action.payload.document : state;
            state.creating = false;
            state.err = undefined;
            break;
    }

    return state;
};





