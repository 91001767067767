import * as types from "../../actions/action-creators/types";
import initialState from "./init";

export default function (state = initialState, action) {
    state = Object.assign({}, state);
    switch (action.type) {
        case types.FETCH_INSURANCE_START:
            state.loading = true;
            state.insur = [];
            break;
        case types.FETCH_INSURANCE_SUCCESS:
            state.loading = false;
            break;
        case types.FETCH_INSURANCE_FAIL:
            state.loading = false;
            break;
        case types.FETCH_INSURANCE_LIST:
            state.insur = action.payload.insur;
            break;
        case "persist/REHYDRATE":
            state = action.payload ? action.payload.insurance : state;
            break;
    }
    return state;
}


