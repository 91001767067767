import React from "react";

const MktRoutes = [
  {
     path: "/mkt",
   
    component: React.lazy(() => import("./Mkt")),
  
  }
];

export default MktRoutes;
